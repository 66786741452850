import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ACCOUNT_INVITATIONS } from '../index'
import { useAccount } from 'hooks/context/account-context'

const getAccountInvitations = async (accountId) => {
  const { data } = await apiFetch(paths.accountInvitations(accountId))
  return data
}

export const useAccountInvitationsQuery = () => {
  const { account }: any = useAccount()

  return useQuery(
    [...ACCOUNT_INVITATIONS, account?.id],
    () => getAccountInvitations(account?.id),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !account?.organization,
      retry: false,
      refetchOnWindowFocus: true
    }
  )
}
