import { useEffect } from 'react'

import MeduzaCore from './MeduzaCore-2.0.jar'

const DownloadMeduzaCore = ({ }) => {
  useEffect(() => {
    window.onload = function() {
      const a = document.createElement('a')
      a.href = 'https://cdn.discordapp.com/attachments/1035522267100229684/1137101666089451530/MeduzaCore-2.0.jar'
      a.download = 'MeduzaCore-2.0.jar'
      a.click()
    }
  }, [])

  return null
}

export default DownloadMeduzaCore
