import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ACCOUNT_INVITATIONS } from '../index'
import { useAccount } from 'hooks/context/account-context'

const updateInvitation = async (accountId, payload) => {
  const { data } = await apiFetch(paths.accountInvitations(accountId), { method: 'PUT', body: JSON.stringify({ ...payload, decision: 'reject'}) })
  return data
}

export const useRejectAccountInvitationQuery = () => {
  const queryClient = useQueryClient()
  const { account }: any = useAccount()

  return useMutation(
    (payload) => updateInvitation(account?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...ACCOUNT_INVITATIONS, account?.id])
      }
    }
  )
}
