import React, { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { COLORS } from '../../../lib/constants/colors'
import Text from 'components/text'

export const Wrapper = withTheme(styled.div`
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 15rem;
  width: 25rem;
  margin: 10px 5%;
  padding: 10px 15px;

  border-radius: 25px;
  background-color: ${({theme}) => theme.colors.BACKGROUND};
  color: ${props => props.theme.colors.FONT}; 
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);

  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
    box-shadow: ${({disabled, theme}) => disabled ? 'none' : `3px 3px 40px 5px ${theme.colors.BACKGROUND}`};
  }
`)

const Card = ({ text, onClick, disabled }) => {
  return (<Wrapper onClick={onClick} disabled={disabled}>
    <Text type ='h4'>{text}</Text>
  </Wrapper>
    
  )
}

export default Card
