import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ORGANIZATION, useOrganizationQuery } from './index'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import { useAccount } from 'hooks/context/account-context'

const postOrganization = async (payload) => {
  const { data } = await apiFetch(paths.organizations(), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

export const useCreateOrganizationsQuery = () => {
  const queryClient = useQueryClient()
  const { refetch } = useOrganizationQuery()
  const { refreshCurrentSession } = useUser()
  const { setOrganization } = useOrganization()
  const { setAccount, account } = useAccount()

  return useMutation(
    (payload) => postOrganization(payload),
    {
      onSuccess: (organization) => {
        queryClient.invalidateQueries([ORGANIZATION, organization?.id])
        refreshCurrentSession(refetch)

        if (!organization) return setOrganization({})
        setAccount({ ...(account as any), organization: organization.id })

        queryClient.setQueryData([ORGANIZATION, organization?.id], organization)
        return setOrganization(organization)
      }
    }
  )
}
