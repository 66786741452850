import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ORGANIZATION } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const postInvitation = async (organizationId, payload) => {
  const { data } = await apiFetch(paths.invitations(organizationId), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

export const useSendInvitationQuery = () => {
  const queryClient = useQueryClient()
  const { organization }: any = useOrganization()

  return useMutation(
    (payload) => postInvitation(organization?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ORGANIZATION])
      }
    }
  )
}
