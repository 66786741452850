import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;
  min-width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 10px 0;
  padding: 30px 20px;
`

export const Back = styled.div`
  background-color: white;
  opacity: ${props => props.disabled ? 0 : 0.1};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 10%;
  height: 100%;

  &:hover {
    transition: all 0.1s ease;
    opacity: ${props => !props.disabled && 0.5};
  }
`

export const Next = styled.div`
  background-color: white;
  opacity: ${props => props.disabled ? 0 : 0.1};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 10%;
  height: 100%;

  &:hover {
    transition: all 0.1s ease;
    opacity: ${props => !props.disabled && 0.5};
  }
`


export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  margin: 10px;
  margin-top: 10%;
`