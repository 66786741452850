import React, { createContext, useContext, useMemo, useState } from 'react'
import { isUndefined } from 'lodash'

export const CURRENT_PURCHASES = 'current_purchases_context'

export const PurchasesContext = createContext({
  purchases: null,
  setPurchases: (purchases) => { }
})

export const setLocalStorage = (purchases) => {
  localStorage.setItem(CURRENT_PURCHASES, JSON.stringify(purchases))
}

export const getLocalStorage = () => {
  const purchases = !isUndefined(localStorage.getItem(CURRENT_PURCHASES)) ? JSON.parse(localStorage.getItem(CURRENT_PURCHASES)) : {}
  return { purchases }
}

export const PurchasesProvider = (props) => {
  const [purchases, setPurchasesContext] = useState(null)

  const setPurchases = (purchases) => {
    setLocalStorage(purchases)
    setPurchasesContext(purchases)
  }

  const values = useMemo(() => ({ purchases, setPurchases }), [purchases])

  return (
    <PurchasesContext.Provider value={values}>
      {props.children}
    </PurchasesContext.Provider>
  )
}

export const usePurchases = () => {
  const context = useContext(PurchasesContext)

  if (context === undefined) throw new Error('`usePurchases` must be within a `UserProvider`')

  return context
}
