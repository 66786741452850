import Amplify from 'aws-amplify'

const {
  REACT_APP_AWS_COGNITO_REGION = 'us-east-1',
  REACT_APP_AWS_COGNITO_USER_POOL_AUTHENTICATION_FLOW_TYPE = 'USER_PASSWORD_AUTH',
  REACT_APP_AWS_COGNITO_USER_POOL_ID = '',
  REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID = ''
} = process.env

export default () => {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: REACT_APP_AWS_COGNITO_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: REACT_APP_AWS_COGNITO_USER_POOL_AUTHENTICATION_FLOW_TYPE
    }
  })
}
