import React, { createContext, useContext, useMemo, useState } from 'react'
import { isUndefined } from 'lodash'

export const CURRENT_ACCOUNT = 'current_account_context'

export const AccountContext = createContext({
  account: null,
  setAccount: (account) => { }
})

export const setLocalStorage = (account) => {
  localStorage.setItem(CURRENT_ACCOUNT, JSON.stringify(account))
}

export const getLocalStorage = () => {
  const account = !isUndefined(localStorage.getItem(CURRENT_ACCOUNT)) ? JSON.parse(localStorage.getItem(CURRENT_ACCOUNT)) : {}
  return { account }
}

export const AccountProvider = (props) => {
  const [account, setAccountContext] = useState(null)

  const setAccount = (account) => {
    setLocalStorage(account)
    setAccountContext(account)
  }

  const values = useMemo(() => ({ account, setAccount }), [account])

  return (
    <AccountContext.Provider value={values}>
      {props.children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => {
  const context = useContext(AccountContext)

  if (context === undefined) throw new Error('`useAccount` must be within a `UserProvider`')

  return context
}
