import { useMutation } from 'react-query'
import { Auth } from 'aws-amplify'

import { useUser } from '../context/user-context'
import { MSG_RESENT_PASSWORD_RESET_LINK } from './messages'

type ForgotPasswordPayload = {
  email: string
  code: string
  password: string
}

export const useForgotPassword = () => {
  const { setAuthMessage } = useUser()
  return useMutation(
    (email: string) => Auth.forgotPassword(email),
    {
      onSuccess: () => {
        setAuthMessage(MSG_RESENT_PASSWORD_RESET_LINK)
      },
      onError: (err: any) => {
        return setAuthMessage({ message: err.message })
      }
    },
  )
}

export const useForgotPasswordSubmit = () => {
  const { setAuthMessage } = useUser()

  return useMutation(
    ({ email, code, password }: ForgotPasswordPayload) => Auth.forgotPasswordSubmit(email, code, password),
    {
      onSuccess: () => {
        setAuthMessage(false)
      },
      onError: (err: any) => {
        return setAuthMessage({ message: err.message })
      }
    }
  )
}

export const useCompleteNewPassword = () => {
  const { user, setUser, setAuthenticated } = useUser()
  return useMutation(
    (password: string) => Auth.completeNewPassword(user, password),
    {
      onSuccess: (cognitoUser: any) => {
        setUser(cognitoUser)
        setAuthenticated(!cognitoUser.challengeName)
      }
    }
  )
}
