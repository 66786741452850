import styled, { withTheme } from 'styled-components'

export const UltraWrapper = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  flex: 7;
  overscroll-behavior-y: none;
  padding: 0;
  margin: 12px;
  margin-left: 0px;
  padding: 20px 30px;
`

export const MainWidget = withTheme(styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.colors.BACKGROUND};
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);

  padding: 10px;
`)

export const Widget = withTheme(styled.div`
  display: flex;
  flex-direction: column;
`)
