import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { ORGANIZATION, organizationsPaths as paths, useOrganizationQuery } from './index'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'

const updateOrganization = async (organizationId, payload) => {
  const { data } = await apiFetch(paths.organization(organizationId), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateOrganizationQuery = () => {
  const queryClient = useQueryClient()
  const { refetch } = useOrganizationQuery()
  
  const { refreshCurrentSession } = useUser()
  const { organization, setOrganization }: any = useOrganization()

  return useMutation(
    (payload) => updateOrganization(organization?.id, payload),
    {
      onMutate: (payload: any) => {
        const previousOrganization: any = queryClient.getQueryData([ORGANIZATION, organization?.id])

        const newOrganization = { ...previousOrganization, ...payload }
        setOrganization(newOrganization)
        queryClient.setQueryData([ORGANIZATION, organization?.id], newOrganization)

        return { previousOrganization }
      },
      onError: (err, newItem, context: { previousOrganization: any }) => {
        if (err) queryClient.setQueryData([ORGANIZATION, organization?.id], context.previousOrganization)
      },
      onSuccess: () => {
        refreshCurrentSession(refetch)
      }
    }
  )
}
