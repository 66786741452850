import { useState } from 'react'
import { Alert, Skeleton } from 'antd'
import { useHistory } from 'react-router-dom'

import Text from 'components/text'
import { Input } from '@geist-ui/core'
import PlayerCard from './player-card'
import { useOrganization } from 'hooks/context/organization-context'
import { useElyziumAllUserDataQuery, useElyziumPunishmentsQuery, useElyziumWarningsQuery } from 'hooks/api/connectors'

import { UltraWrapper } from './styles'
import { useConnector } from 'hooks/context/connector-context'

const Playerbase = () => {
  const history = useHistory()

  const [searchValue, setSearchValue] = useState()

  const { organization } = useOrganization()
  const { connector } = useConnector()

  const { data: elyziumUserData, isLoading: isLoadingElyziumUserData } = useElyziumAllUserDataQuery()
  const { data: elyziumPunishments, isLoading: isLoadingElyziumPunishments } = useElyziumPunishmentsQuery()
  const { data: elyziumWarnings, isLoading: isLoadingElyziumWarnings } = useElyziumWarningsQuery()

  const isLoading = isLoadingElyziumUserData || isLoadingElyziumPunishments || isLoadingElyziumWarnings

  if (organization) {
    return (
      <UltraWrapper>
          {!connector && <Alert message='You do not have the database connected yet, so nothing will be shown here. Connect your database in Settings > Plugins.' type='error' banner />}

          <div style={{display: 'flex', width: '100%', alignSelf: 'flex-start', borderBottom: '1px solid rgba(255, 255, 255, 0.2)', marginBottom: '30px'}}>
            <Text type='h1'>Playerbase</Text>
          </div>
          <div style={{display: 'flex', marginBottom: '40px', justifyContent: 'center'}}>
            <Input placeholder='Player name' onChange={(e: any) => setSearchValue(e.target.value)} />
          </div>

          <div style={{ overflowY: 'scroll', display: 'flex', width: '100%', justifyContent: 'space-around',  flexWrap: 'wrap'}}>
            {isLoading ? [1, 2, 3, 4].map(() => <Skeleton style={{width: '15rem', height: '20rem'}} active />)
            : elyziumUserData?.filter(item => item.name.includes(searchValue || '')).map(item => (
              <PlayerCard
                onClick={() => history.push(`/playerbase/${item.uuid}`)}
                userData={item} 
                elyziumStats={{ 
                  punishments: elyziumPunishments?.filter(p => p.punishedUuid === item.uuid),
                  warnings: elyziumWarnings?.filter(p => p.warnedUuid === item.uuid),
                }} 
              />
            ))}
          </div>
      </UltraWrapper>
    )
  }

  return null
}

export default Playerbase
