import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { isEmpty, sum } from 'lodash'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Text from 'components/text'
import Paywall from 'components/paywall'
import { useOrganization } from 'hooks/context/organization-context'

import { UltraWrapper, MainWidget } from './styles'
import { useElyziumPunishmentsByUuidQuery, useElyziumUserDataQuery, useElyziumWarningsByUuidQuery } from 'hooks/api/connectors'

import Widget from './widgets/default'
import Punchcard from './widgets/punchcard'
import BanDrawer from './drawers/ban-drawer'
import MuteDrawer from './drawers/mute-drawer'
import WarningDrawer from './drawers/warning-drawer'

export const Avatar = styled.img`
  width: 100px;
  height: 200px;
`

const validateResponse = (response) => {
  if (!response.ok) throw Error(response.statusText)
  return response
}

const Playerbase = () => {
  const { playerUuid } = useParams()

  const [avatar, setAvatar] = useState()
  const [banDrawerOpen, setBanDrawerOpen] = useState(false)
  const [muteDrawerOpen, setMuteDrawerOpen] = useState(false)
  const [warningDrawerOpen, setWarningDrawerOpen] = useState(false)

  const { organization: currentOrganization } = useOrganization()

  const { data: elyziumUserData } = useElyziumUserDataQuery(playerUuid)
  const { data: elyziumPunishments } = useElyziumPunishmentsByUuidQuery(playerUuid)
  const { data: elyziumWarnings } = useElyziumWarningsByUuidQuery(playerUuid)

  const fetchAvatar = async (uuid) => fetch(`https://crafatar.com/renders/body/${uuid}`).then(validateResponse).then(response => response.blob()).then(blob => setAvatar(URL.createObjectURL(blob) as any))
  useEffect(() => {
    if (!elyziumUserData) return
    fetchAvatar(elyziumUserData?.uuid)
  }, [elyziumUserData])

  if (!isEmpty(currentOrganization)) {
    return (
      <UltraWrapper>
          <div style={{display: 'flex', width: '100%', alignSelf: 'flex-start', borderBottom: '1px solid rgba(255, 255, 255, 0.2)', marginBottom: '30px'}}>
            <Text type='h1'>Profile of {elyziumUserData?.name || 'Unknown'}</Text>
          </div>

          <BanDrawer onClose={() => setBanDrawerOpen(false)} open={banDrawerOpen} bans={elyziumPunishments?.filter(item => item.type === 'BAN')} />
          <MuteDrawer onClose={() => setMuteDrawerOpen(false)} open={muteDrawerOpen} mutes={elyziumPunishments?.filter(item => item.type === 'MUTE')} />
          <WarningDrawer onClose={() => setWarningDrawerOpen(false)} open={warningDrawerOpen} warnings={elyziumWarnings} />

          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <div style={{display: 'column', width: '30%'}}>
              <MainWidget style={{height: '400px'}}>
                <div style={{display: 'flex'}}>
                  <Avatar src={avatar} alt='Player avatar' style={{marginRight: '20px'}}/>
                  <Paywall />
                </div>
                <div style={{display: 'flex', marginTop: '10px', height: '100%', width: '100%', paddingLeft: '10px'}}>
                  <Paywall />
                </div>
                <div style={{display: 'flex', marginTop: 'auto' }}>
                  <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px', width: '50%'}}>
                    <Text type='mini' style={{marginRight: '5px'}}>First Login: </Text>
                    <Text type='boldLabel'>{dayjs(parseInt(elyziumUserData?.lastLogin)).format('HH:mm - DD MMM \'YY')}</Text>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px', width: '50%'}}>
                    <Text type='mini' style={{marginRight: '5px'}}>Last Login: </Text>
                    <Text type='boldLabel'>{dayjs(parseInt(elyziumUserData?.lastLogin)).format('HH:mm - DD MMM \'YY')}</Text>
                  </div>
                </div>
              </MainWidget>

              <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
                <Widget onClick={() => setBanDrawerOpen(true)} value={elyziumPunishments?.filter(item => item.type === 'BAN').length} description='Times Banned' tag={elyziumPunishments?.filter(ban => ban.type === 'BAN' && parseInt(ban.createdAt) > dayjs().subtract(7, 'days').valueOf()).length} negativeTag={elyziumPunishments?.filter(ban => ban.type === 'BAN' && parseInt(ban.createdAt) > dayjs().subtract(7, 'days').valueOf()).length > 0} small/>
                <Widget onClick={() => setMuteDrawerOpen(true)} value={elyziumPunishments?.filter(item => item.type === 'MUTE').length} description='Times Muted' tag={elyziumPunishments?.filter(mute => mute.type === 'MUTE' && parseInt(mute.createdAt) > dayjs().subtract(7, 'days').valueOf()).length} negativeTag={elyziumPunishments?.filter(mute => mute.type === 'MUTE' && parseInt(mute.createdAt) > dayjs().subtract(7, 'days').valueOf()).length > 0} small/>
                <Widget onClick={() => setWarningDrawerOpen(true)} value={sum(elyziumWarnings?.map(item => item.severity) || [])} description='Warning Points' tag={sum(elyziumWarnings?.filter(warning => parseInt(warning.createdAt) > dayjs().subtract(7, 'days').valueOf()).map(warning => warning.severity) || [])} negativeTag={sum(elyziumWarnings?.filter(warning => parseInt(warning.createdAt) > dayjs().subtract(7, 'days').valueOf()).map(warning => warning.severity) || []) > 0} small/>
              </div>

              <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
                <Widget value={elyziumUserData?.ipLockEnabled === 1 ? 'Yes' : 'No'} description='IP Locked' small/>
              </div>
            </div>

            <div style={{boxSizing: 'border-box', display: 'flex', flexDirection: 'column', width: '70%', marginLeft: '10px'}}>
              <MainWidget style={{width: '100%', height: '400px'}}>
                <Paywall>
                  <Punchcard />
                </Paywall>
              </MainWidget>
            </div>

          </div>
      </UltraWrapper>
    )
  }

  return null
}

export default Playerbase
