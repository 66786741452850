import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useUser } from '../context/user-context'
import { MSG_RESENT_VERIFICATION_LINK } from './messages'
import { useUserQuery } from 'hooks/api/organizations'
import { Hub } from 'aws-amplify'


type SignupPayload = {
  userId: string
  password: string
  attributes: any
}

type ConfirmSignupPayload = {
  email: string
  code: string
}

export const useSignup = () => {
  const { setUser, setAuthMessage } = useUser()

  return useMutation(
    ({ password, attributes }: SignupPayload) => {
      return Auth.signUp({
        username: attributes.email,
        password,
        attributes
      })
    },
    {
      onSuccess: (cognitoUser) => {
        setUser(cognitoUser)
      },
      onError: (error: any) => {
        if (error.code === 'UsernameExistsException') return setAuthMessage({ title: 'User already exists', message: error.message, type: 'error' })
        setAuthMessage({ title: 'Some error occurred', message: error.message, type: 'error' })
      }
    }
  )
}

export const useConfirmSignup = () => {
  const history = useHistory()
  const { refreshCurrentSession, setAuthMessage } = useUser()
  const { refetch } = useUserQuery()

  useListenToAutoSignInEvent()

  return useMutation(
    ({ email, code }: ConfirmSignupPayload) => Auth.confirmSignUp(email, code),
    {
      onSuccess: async () => {
        setAuthMessage(null)
        await refreshCurrentSession(refetch)
        history.push('/')
      },
      onError: async (error) => {
        console.log('Encountered an error', error)
        setAuthMessage(error)
      },
    }
  )
}

export const useResendSignUp = () => {
  const { setAuthMessage } = useUser()
  return useMutation(
      (email: string) => Auth.resendSignUp(email),
    {
      onSuccess: () => {
        setAuthMessage(MSG_RESENT_VERIFICATION_LINK)
      }
    }
  )
}


export const useListenToAutoSignInEvent = () => {
  const history = useHistory()
  const { refreshCurrentSession, setAuthMessage } = useUser()
  const { refetch } = useUserQuery()

  Hub.listen('auth', async ({ payload }) => {
      const { event } = payload
      if (event === 'autoSignIn') {
          setAuthMessage(null)
          await refreshCurrentSession(refetch)
          history.push('/')
      } else if (event === 'autoSignIn_failure') {
          // redirect to sign in page
          history.push('/auth/login')
      }
  })
}