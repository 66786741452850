import React, { createContext, useContext, useMemo, useState } from 'react'
import { isUndefined } from 'lodash'

export const CURRENT_ORGANIZATION = 'current_organization_context'

export const OrganizationContext = createContext({
  organization: null,
  setOrganization: (organization) => { }
})

export const setLocalStorage = (organization) => {
  localStorage.setItem(CURRENT_ORGANIZATION, JSON.stringify(organization))
}

export const getLocalStorage = () => {
  const organization = !isUndefined(localStorage.getItem(CURRENT_ORGANIZATION)) ? JSON.parse(localStorage.getItem(CURRENT_ORGANIZATION)) : {}
  return { organization }
}

export const OrganizationProvider = (props) => {
  const [organization, setContextOrganization] = useState(null)

  const setOrganization = (organization) => {
    setLocalStorage(organization)
    setContextOrganization(organization)
  }

  const values = useMemo(() => ({ organization, setOrganization }), [organization])

  return (
    <OrganizationContext.Provider value={values}>
      {props.children}
    </OrganizationContext.Provider>
  )
}

export const useOrganization = () => {
  const context = useContext(OrganizationContext)

  if (context === undefined) throw new Error('`useOrganization` must be within a `UserProvider`')

  return context
}
