import styled, { withTheme } from 'styled-components'

import { VscNote, VscAccount, VscCircleSlash, VscMute } from 'react-icons/vsc'

export const UltraWrapper = styled.div`
display: flex;
flex: 7;
overscroll-behavior-y: none;
padding: 0;
margin: 12px;
margin-left: 0px;
padding-left: 30px;
padding-right: 30px;
padding-bottom: 20px;
padding-top: 20px;
min-height: 97.7vh;
height: 100%;
width: 100%;
border-radius: 0px 15px 15px 0px;
`

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding-left: 30px;

min-height: 100%;
min-width: 100%;

margin: 10px 0;
`

export const CardsContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;

width: 100%;

margin-top: 5%;
`

export const BannedPlayers = withTheme(styled(VscCircleSlash)`
width: 30%;
font-size: 3rem;
padding: 0px;
margin-right: 10px;
color: ${props => props.theme.colors.FONT};
`)

export const NewTicketsReceived = withTheme(styled(VscNote)`
width: 30%;
font-size: 3rem;
padding: 0px;
margin-right: 10px;
color: ${props => props.theme.colors.FONT};
`)

export const NewPlayersToday = withTheme(styled(VscAccount)`
width: 30%;
font-size: 3rem;
padding: 0px;
margin-right: 10px;
color: ${props => props.theme.colors.FONT};
`)

export const MutedPlayers = withTheme(styled(VscMute)`
width: 30%;
font-size: 3rem;
padding: 0px;
margin-right: 10px;
color: ${props => props.theme.colors.FONT};
`)
