import { notification, Button, message } from 'antd'
import { useTheme } from 'styled-components'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

// Hooks
import { useOrganization } from 'hooks/context/organization-context'
import { useAccountInvitationsQuery, useAcceptAccountInvitationsQuery, useRejectAccountInvitationQuery } from 'hooks/api/organizations'
import { useElyziumBannedPlayersInLast24hQuery, useElyziumMutedlayersInLast24hQuery, useElyziumNewTicketsOpenedLast24hQuery, useElyziumUniqueLoginsLast24hQuery } from 'hooks/api/connectors'

// Components
import Text from 'components/text'

// Local components
import Onboarding from './Onboarding'
import ServerCard from './ServerCard'
import MetricCard from './MetricCard'

// Styled components
import { UltraWrapper, CardsContainer, BannedPlayers, MutedPlayers, NewTicketsReceived, NewPlayersToday, Wrapper } from './styles'
import { useConnector } from 'hooks/context/connector-context'
import { useAccount } from 'hooks/context/account-context'
import { usePurchases } from 'hooks/context/purchases-context'
import CustomButton from 'components/Button'

notification.config({
  maxCount: 1
})

const Dashboard = () => {
  const theme = useTheme()
  const history = useHistory()
  const { account }: any = useAccount()
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()
  const { purchases }: any = usePurchases()

  const { data: invitations, isLoading: isLoadingInvitations } = useAccountInvitationsQuery()

  const { data: bannedPlayersInLast24h } = useElyziumBannedPlayersInLast24hQuery()
  const { data: mutedPlayersInLast24h } = useElyziumMutedlayersInLast24hQuery()
  const { data: uniqueLoginsInLast24h } = useElyziumUniqueLoginsLast24hQuery()
  const { data: newTicketsOpenedInLast24h } = useElyziumNewTicketsOpenedLast24hQuery()

  const { mutateAsync: acceptInvitation, isLoading: acceptInvitationLoading } = useAcceptAccountInvitationsQuery()
  const { mutateAsync: rejectInvitation, isLoading: rejectInvitationLoading } = useRejectAccountInvitationQuery()

  const hasPermissionForElyzium = !!organization?.memberships?.find(membership => membership.account === account?.id)?.permissions?.find(permission => permission.product === 'elyzium')

  const Elyzium = () => (
    <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', marginTop: '40px'}}>
      <Text type='h3'>Elyzium</Text>
      <CardsContainer>
        <MetricCard icon={<BannedPlayers />} title='New banned players' value={bannedPlayersInLast24h?.length} />
        <MetricCard icon={<MutedPlayers />} title='New muted players' value={mutedPlayersInLast24h?.length} />
        <MetricCard icon={<NewTicketsReceived />} title='New tickets opened' value={newTicketsOpenedInLast24h?.length} subtitle='Taken from the last 24 hours' ok={true} />
        <MetricCard icon={<NewPlayersToday />} title='Last 24h logins' value={uniqueLoginsInLast24h?.length} ok={true} />
      </CardsContainer>
    </div>
  )

  const openNotification = () => {
    const key = `open${Date.now()}`
    const btn = (
      <>
        <Button style={{marginRight: '10px', backgroundColor: theme.colors.SUCCESS_300, border: 'none'}} type="primary" size="small" onClick={async () => {
          notification.destroy(key)
          const accept = await acceptInvitation({ token: invitations[0]?.token, email: invitations[0]?.email } as any)
          if (accept?.organization) message.success('Successfully joined the organization', 10)
          else  message.error('Something went wrong when trying to accept the invitation', 10)

          notification.destroy()
        }}>
          Accept
        </Button>
        <Button style={{backgroundColor: theme.colors.ERROR_100, border: 'none'}} type="primary" size="small" onClick={async () => {
          notification.destroy(key)
          const reject = await rejectInvitation({ token: invitations[0]?.token, email: invitations[0]?.email } as any)
          if (reject) message.success('Rejected the invitation', 10)
          else message.error('Something went wrong when trying to reject the invitation', 10)

          notification.destroy()
        }}>
          Reject
        </Button>
      </>
    )
    notification.open({
      message: 'Organization Invitation',
      description:
        `You have been invited to join the ${invitations[0]?.organization?.name} organization.`,
      btn,
      key,
      duration: 0
    })
  }

  if (!isEmpty(invitations) 
    && !isLoadingInvitations 
    && !acceptInvitationLoading 
    && !rejectInvitationLoading) openNotification()

  if (!purchases?.length) return (
    <UltraWrapper>
      <Wrapper>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%' }}>
          <Text type='h3' style={{ textAlign: 'center' }}>Looks like you do not have any verified purchases yet.</Text>
          <Text type='h3' style={{ textAlign: 'center', marginBottom: '20px' }}>{'In order to verify purchases, click the button below.'}</Text>

          <CustomButton title='Verify' onClick={() => history.push('/plugins')} />
        </div>
      </Wrapper>
    </UltraWrapper>
  )

  if (organization) {
    if (!connector) return (
      <UltraWrapper>
        <Wrapper>
          <div style={{display: 'flex', width: '100%', alignSelf: 'flex-start', borderBottom: '1px solid rgba(255, 255, 255, 0.2)', marginBottom: '30px'}}>
            <Text type='h1'>Dashboard</Text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%' }}>
            <Text type='h3' style={{ textAlign: 'center' }}>Looks like you do not have a Database connection set up yet.</Text>
            <Text type='h3' style={{ textAlign: 'center' }}>{'In order to show you information about the plugins, please set up your Database details under Settings > Plugins.'}</Text>
          </div>
        </Wrapper>
      </UltraWrapper>
    )

    return <UltraWrapper><Wrapper>
      <div style={{display: 'flex', width: '100%', alignSelf: 'flex-start', borderBottom: '1px solid rgba(255, 255, 255, 0.2)', marginBottom: '30px'}}>
        <Text type='h1'>Dashboard</Text>
      </div>
      {!isEmpty(organization?.servers) && <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'flex-start'}}>
        <Text type='h3'>Servers</Text>
        <CardsContainer>
          {organization?.servers.map((server, index) => <ServerCard server={server} index={index} />)}
        </CardsContainer>
      </div>}
      {hasPermissionForElyzium && <Elyzium />}
    </Wrapper></UltraWrapper>
  }

  return (<>
      <Onboarding />
    </>)
}

export default Dashboard
