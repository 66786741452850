import React from 'react'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Popconfirm, Typography } from 'antd'
import CustomButton from 'components/Button'

// import RouterLink from 'components/router-link'

const { Text } = Typography

const SuspensionButton = ({ onClick, organization = false }) => {
  const theme = useTheme()

  // const organization = useSelector(state => get(state, 'organizations.current'))
  // const onClick = () => suspended ? dispatch(API.organizations.id(organization.id).unsuspend()) : dispatch(API.organizations.id(organization.id).suspend())
  // const loadingSuspend = useSelector(state => state.waiting.list[SUSPEND_ORGANIZATION])
  // const loadingUnsuspend = useSelector(state => state.waiting.list[UNSUSPEND_ORGANIZATION])
  // const loadingSuspension = loadingSuspend || loadingUnsuspend

  // const title = suspended ? 'Unsuspend' : 'Suspend'
  // const disabled = suspended && !paymentMethodExists
  return (
    <>
      <Popconfirm
        title={`Are you sure you want to delete your ${organization ? 'organization' : 'account'}?`}
        onConfirm={onClick}
        okText='Yes'
        cancelText='No'
      >
        <CustomButton
          title='Delete'
          btnType='primary'
          block
          style={{ backgroundColor: theme.colors.ERROR_100, color: theme.colors.FONT }}
          // loading={loading || loadingSuspension}
          // disabled={disabled}
          // className={styles.suspendButton}
        >Delete</CustomButton>
      </Popconfirm>
    </>
  )
}

export default SuspensionButton