import React from 'react'
import { Form } from 'antd'
import Input from '../../../../components/Input'
import Text from 'components/text'

// Specialized components
import { MailIcon, LockIcon, ActionButton } from '../styles'


const Register = ({ disabled, loading, onSubmit, setPassword, setConfirmPassword, setEmail, email, password, confirmPassword }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  return <Form onFinish={onSubmitWrapper} name='register' initialValues={{ remember: true }} style={{width: '40%'}}>
    <Form.Item name='email' hasFeedbackrules={[{ required: true, message: <p>Please input email</p> }]} style={{marginTop: '40px'}} hasFeedback>
      <Text>Email</Text>
      <Input prefix={<MailIcon />} placeholder='Email address' onChange={(e) => setEmail(e.target.value)} />
    </Form.Item>

    <Form.Item name='password' hasFeedbackrules={[{ required: true, message: <Text>Please input the correct password</Text> }]} style={{marginTop: '40px'}} hasFeedback>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text>Password</Text>
      </div>
      <Input prefix={<LockIcon />} placeholder='Password' type='password' onChange={(e) => setPassword(e.target.value)} />
    </Form.Item>

    <Form.Item name='confirmPassword' hasFeedbackrules={[{ required: true, message: <Text>The passwords do not match</Text> }]} style={{marginTop: '40px'}} hasFeedback>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text>Confirm password</Text>
      </div>
      <Input prefix={<LockIcon />} placeholder='Confirm password' type='password' onChange={(e) => setConfirmPassword(e.target.value)} />
    </Form.Item>

    <Form.Item style={{ marginBottom: 0 }}>
      <ActionButton available={email && password && confirmPassword} disabled={disabled} loading={loading} type='primary' htmlType='submit' style={{ width: '100%' }}>
        Sign up
      </ActionButton>
    </Form.Item>
  </Form>
}

export default Register
