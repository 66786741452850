import { useTheme } from 'styled-components'

import Text from 'components/text'
import dayjs from 'dayjs'

const PunchcardWidget = () => {
  const theme = useTheme()

  const columns = Array.from({ length: dayjs().daysInMonth() + 1 }, (_, i) => i + 1)
  const testRows = Array.from({ length: 24 }, (_, i) => i + 1)
  const rows = ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00', '23:00']
  
  return <div style={{display: 'flex', boxSizing: 'border-box', width: '100%', padding: '0 10px'}}>
    {columns.map(num => (
      <div style={{display: 'flex', flexDirection: 'column', width: '90%', alignItems: 'center' }}>
        <Text type='mini' style={{marginBottom: '10px'}}>{num}</Text>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {testRows.map(row => <div style={{width: '5px', height: '5px', background: theme.colors.MAIN, marginBottom: '9px', borderRadius: '15px'}}></div>)}
        </div>
      </div>
    ))}
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginLeft: '20px'}}>{rows.map(row => <Text type='mini'>{row}</Text>)}</div>
  </div>
}

export default PunchcardWidget
