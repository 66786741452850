import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { isEmpty } from 'lodash'
import { v4 } from 'uuid'

// General components
import Register from './components/Register'
import RegisterConfirm from './components/RegisterConfirm'
import Text from 'components/text'

import { MSG_CONFIRMATION_CODE } from 'lib/auth/messages'
import { useUser } from 'hooks/context/user-context'
import { useConfirmSignup, useLogin, useSignup } from 'hooks/auth'

// Specialized components
import { Wrapper, HeaderTextContainer } from './styles'

const RegisterPage = () => {
  const history = useHistory()

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [code, setCode] = useState()

  const { mutate: confirmSignUp, isSuccess: confirmSignUpSuccess, isLoading: signUpConfirmLoading } = useConfirmSignup()
  const { mutate: signUp, isLoading: signUpLoading, isSuccess: signUpSuccess } = useSignup()
  const { mutate: login, isLoading: loginLoading } = useLogin()
  const { user, setAuthMessage, authMessage } = useUser()

  const userHasCodeDelivery = user && user.codeDeliveryDetails

  const onSignUp = async () => {
    await signUp({ userId: v4(), password, attributes: { email } })
  }

  const onConfirmSignUp = async () => {
    await confirmSignUp({ email: user.user.username, code })
  }

  const handleSignUpSuccess = () => {
    if (!signUpSuccess || !password || !email) return
    if (confirmSignUpSuccess) history.push('/')
    login(email, password)
  }

  useEffect(handleSignUpSuccess, [confirmSignUpSuccess])

  useEffect(() => {
    if (userHasCodeDelivery) setAuthMessage(MSG_CONFIRMATION_CODE)  
  }, [user])

  useEffect(() => {
    if (!authMessage) return
    if (authMessage.title === 'Confirmation Code') {
      return notification.info({
        message: authMessage?.message,
        placement: 'topRight',
        duration: 10
      })
    }

    notification.error({
      message: authMessage?.message || 'Something went wrong',
      placement: 'topRight',
      duration: 10
    })
  }, [authMessage])

  const getForm = () => { 
    if (userHasCodeDelivery) {
      return <RegisterConfirm onSubmit={onConfirmSignUp} loading={signUpConfirmLoading || loginLoading} email={email} setCode={setCode} code={code} />
    }

    return <Register onSubmit={onSignUp} loading={signUpLoading} email={email} password={password} confirmPassword={confirmPassword} setEmail={setEmail} setPassword={setPassword} setConfirmPassword={setConfirmPassword} disabled={isEmpty(email) || isEmpty(password) || isEmpty(confirmPassword) || password !== confirmPassword}/>
  }

  return (
    <Wrapper>
      <HeaderTextContainer>
        <Text type='h1'>Sign Up</Text>
        <Text type='boldLabel'>Create an account in seconds</Text>
      </HeaderTextContainer>
      {getForm()}
      <div style={{display: 'flex'}}>
        <Text type='mini' style={{marginRight: '5px'}}>Already signed up? </Text> 
        <Link to='/auth/login'>
          <Text type='mini' clickable>Sign In</Text>
        </Link>
      </div>
    </Wrapper>
  )
}

export default RegisterPage
