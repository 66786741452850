import styled from 'styled-components'

export const UltraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 7;
  overscroll-behavior-y: none;
  padding: 0;
  margin: 12px;
  margin-left: 0px;
  padding: 20px 30px;
`
