import React from 'react'
import { Form } from 'antd'
import Input from '../../../../components/Input'

// Specialized components
import { MailIcon, ActionButton } from '../styles'

import Text from 'components/text'

const Register = ({ disabled, loading, onSubmit, setEmail, email }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  return <Form form={form} onFinish={onSubmitWrapper} name='register' initialValues={{ remember: true }} style={{width: '40%'}}>
    <Form.Item name='email' hasFeedbackrules={[{ required: true, message: <p>Please input email</p> }]} style={{marginTop: '40px'}} hasFeedback>
      <Text>Email</Text>
      <Input prefix={<MailIcon />} type='email' placeholder='Email address' onChange={(e) => setEmail(e.target.value)} />
    </Form.Item>

    <Form.Item style={{ marginBottom: 0 }}>
      <ActionButton available={email} disabled={!email} loading={loading} type='primary' htmlType='submit' style={{ width: '100%' }}>
        Sign up
      </ActionButton>
    </Form.Item>
  </Form>
}

export default Register
