import React from 'react'
import { Form } from 'antd'
import Input from 'components/Input'
import Text from 'components/text'

// Specialized components
import { MailIcon, LockIcon, ActionButton } from '../styles'


const RegisterConfirm = ({ loading, onSubmit, setCode, code, email }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  return <Form form={form} onFinish={onSubmitWrapper} name='register' initialValues={{ remember: true }} style={{width: '40%'}}>
    <Form.Item name='email' hasFeedbackrules={[{ required: true, message: <p>Please input email</p> }]} style={{marginTop: '40px'}} hasFeedback>
      <Text>Email</Text>
      <Input prefix={<MailIcon />} placeholder={email} disabled />
    </Form.Item>

    <Form.Item name='code' hasFeedbackrules={[{ required: true, message: <Text>Please input the confirmation code</Text> }]} style={{marginTop: '40px'}} hasFeedback>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text>Confirmation Code</Text>
      </div>
      <Input prefix={<LockIcon />} placeholder='Confirmation Code' onChange={(e) => setCode(e.target.value)} />
    </Form.Item>

    <Form.Item style={{ marginBottom: 0 }}>
      <ActionButton available={code && code.length === 6} loading={loading} type='primary' htmlType='submit' style={{ width: '100%' }}>
        Confirm email
      </ActionButton>
    </Form.Item>

  </Form>
}

export default RegisterConfirm
