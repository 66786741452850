import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const ClientLoader = ({ children }) => {
  // const loading = currentSessionLoading || signInLoading || getOrgLoading || getOrgByUserLoading
  const loading = false

  const displayApp = () => {
    document.getElementById('app').style.display = loading ? 'none' : 'block'
    document.getElementById('loading-spinner').style.display = loading ? 'block' : 'none'
  }

  useEffect(displayApp)

  return children
}

export default ClientLoader