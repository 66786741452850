import styled, { useTheme } from 'styled-components'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { Tabs } from 'antd'

import TeamTab from './TeamTab'
import OrganizationTab from './OrganizationTab'
import ProfileTab from './ProfileTab'
import PluginsTab from './PluginsTab'

import Text from 'components/text'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import { useAccount } from 'hooks/context/account-context'
import { useOrganizationQuery } from 'hooks/api/organizations'

const { TabPane } = Tabs

export const UltraWrapper = styled.div`
  display: flex;
  overscroll-behavior-y: none;
  padding: 12px;
  padding-left: 30px;
  min-height: 100vh;
  width: 100vw;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100%;
  min-width: 100%;
`

const Settings = () => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const { organization } = useOrganization()
  const { account } = useAccount()
  const { user: currentUser } = useUser()

  // Make this ID based, not email based!
  const isUserAnOwner = () => organization?.memberships?.find(membership => membership?.account === account?.id)?.role === 'owner'

  return (
    <UltraWrapper>
      <Wrapper>
        <div style={{display: 'flex', width: '100%', alignSelf: 'flex-start', borderBottom: '1px solid rgba(255, 255, 255, 0.2)', marginBottom: '30px'}}>
          <Text type='h1'>Settings</Text>
        </div>
        <Tabs defaultActiveKey='1' size='large' style={{ width: '100%', overflowY: 'scroll', overflowX: 'hidden', color: theme.colors.FONT }}>
          <TabPane tab='Profile' key='1'>
            <ProfileTab />
          </TabPane>
          {(organization && isUserAnOwner()) && <TabPane tab='Organization' key='2'>
            <OrganizationTab />
          </TabPane>}
          {(organization && isUserAnOwner()) && <TabPane tab='Team' key='3'>
            <TeamTab />
          </TabPane>}
          {(organization && isUserAnOwner()) && <TabPane tab='Plugins' key='4'>
            <PluginsTab />
          </TabPane>}
          <TabPane tab='API' key='5'>
            Under development...
          </TabPane>
          {/* <TabPane tab='Billing' key='4'>
            Content of Tab Pane 3
          </TabPane> */}
        </Tabs>
      </Wrapper>
    </UltraWrapper>
  )
}

export default Settings
