import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { purchasePaths as paths, PURCHASE } from './index'
import { useAccount } from 'hooks/context/account-context'

const getPurchases = async (accountId) => {
  const { data } = await apiFetch(paths.purchases(accountId))
  return data || []
}

export const usePurchasesQuery = () => {
  const { account }: any = useAccount()

  return useQuery(
    [PURCHASE, account?.id],
    () => getPurchases(account?.id),
    {
      staleTime: Infinity,
      enabled: !!account?.id,
      refetchOnWindowFocus: false
    }
  )
}
