import styled, { withTheme } from 'styled-components'

import { FiMail, FiLock } from 'react-icons/fi'

const AUTH_MODULE_WIDTH = '40%'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-left: 15%;
`

export const HeaderTextContainer = styled.div`
  width: ${AUTH_MODULE_WIDTH};
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`

export const Text = styled.p`
  font-size: 1rem;
  margin: 0;
`

export const HeaderText = styled(Text)`
  font-size: 4.2rem;
  font-weight: bold;
`

export const LinkText = styled.a`
  font-size: 0.7rem;
  color: #6666FF;
`

export const MailIcon = styled(FiMail)`
  margin-left: 10px;
  margin-right: 15px;
  color: #6666FF;
  font-size: 1.1rem;
`

export const LockIcon = styled(FiLock)`
  margin-left: 10px;
  margin-right: 15px;
  color: #6666FF;
  font-size: 1.1rem;
`

export const ActionButton = withTheme(styled.button`
  background-color: ${props => props.available ? props.theme.colors.MAIN_DARKER : props.theme.colors.GHOST};
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: ${AUTH_MODULE_WIDTH};
  padding: 12px;
  margin-top: 40px;
  margin-bottom: 25px;

  border: none;
  border-radius: 10px;
  
  color: ${props => props.theme.colors.FONT_INVERSE};
  font-weight: bold;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);

  transition: all 0.3s ease;
  &:hover {
    cursor: ${props => props.available ? 'pointer' : 'not-allowed'};
    transform: ${props => props.available ? 'scale(1.01)' : ''};
    background-color: ${props => props.available ? props.theme.colors.MAIN_DARK : props.theme.colors.GHOST};
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
  }
`)

export const SignInSwitch = styled.span`
  font-size: 0.9rem;
  color: #6666FF;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: #5252f8
  }
`
