export const COLORS = {
  DARK_THEME_PLACEHOLDER: '#000',
  // ECEBE4
  // C6F853 / F79F79 / #CDFAFA / #43EFEF
  // FAFAFF


  // 1C1C1C
  // 1768AC
  // #A9FBC3

  DARK_BACKGROUND_LIGHTER: '#353131',
  DARK_BACKGROUND_LIGHT: '#201E1E',
  DARK_BACKGROUND: '#131212',

  DARK_PRIMARY_LIGHTER: '#E5FCB1',
  DARK_PRIMARY_LIGHT: '#D8FA89',
  DARK_PRIMARY: '#C6F853',
  DARK_PRIMARY_DARK: '#BFF73B',
  DARK_PRIMARY_DARKER: '#B2F514',

  DARK_ALT_1: '#A9FBC3',
  DARK_ALT_2: '#A9FFF7',
  DARK_ALT_3: '#FEAEAE',
  DARK_ALT_4: '#D5B9FF',
  
  LIGHT_PRIMARY_LIGHTER: '#FBD6C5',
  LIGHT_PRIMARY_LIGHT: '#F9BA9F',
  LIGHT_PRIMARY: '#F79F79',
  LIGHT_PRIMARY_DARK: '#F58351',
  LIGHT_PRIMARY_DARKER: '#F3672B',

  CELADON: '#A9FBC3',
  LIGHT_CYAN: '#CDFAFA',
  CELESTE: '#A9FFF7',
  RUSSIAN_VIOLET: '#4C1A57',
  MAXIMUM_BLUE_PURPLE: '#B3B3F1',
  SKY_BLUE_CRAYOLA: '#86E7FF',
  FLOURESCENT_BLUE: '#43EFEF',
  SAPPHIRE_BLUE: '#1768AC',
  INCHWORM: '#A3EE58',

  // #F79F79
  PRIMARY_100: '#C6F853',
  SECONDARY_1_100: '#F79F79',
  SECONDARY_2_100: '#A9FBC3',
  SECONDARY_3_100: '#CDFAFA',
  SECONDARY_4_100: '#A9FBC3',
  SECONDARY_5_100: '#43EFEF',
  SECONDARY_6_100: '#1768AC',
  SECONDARY_7_100: '#A3EE58',

  WHITE_100: '#FAFAFF',
  WHITE_200: '#EEF0F2',
  WHITE_300: '#ECEBE4',
  WHITE_400: '#DADDD8',
  EERIE_BLACK: '#1C1C1C',
  EERIE_BLACK_LITE: '#333333',

  GRAY_100: '#A09B8C',
  GRAY_200: '#5B5A56',
  GRAY_300: '#3C3C41',
  GRAY_400: '#1E2328',
  GRAY_COOL: '#1E282D',
  HEXTECH_BLACK: '#010A13',
  CHARCHOAL: '#344055',

  SUCCESS_100: '#E5F9EE',
  SUCCESS_200: '#9EE493',
  SUCCESS_300: '#89DE7C',
  SUCCESS_400: '#6CD65C',
  SUCCESS_500: '#226218',

  ERROR_100: '#FF675C',
  ERROR_200: '#FF4133',
  ERROR_300: '#FF1B0A',
  ERROR_400: '#E00F00',
  ERROR_500: '#7A0800',

  WARNING_100: '#F8FA90',
  WARNING_200: '#F6F977',
  WARNING_300: '#F4F750',

  INFO_100: '#9CDEE7',
  INFO_200: '#6BCEDB',
  INFO_300: '#3ABECF',
}
