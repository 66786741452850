import React from 'react'
import { Form } from 'antd'
import Input from '../../../../components/Input'

// Specialized components
import { Text, MailIcon, LockIcon, ActionButton } from '../styles'


const ForgotPasswordConfirm = ({ loading, onSubmit, setCode, setPassword, setConfirmPassword, email, code, password, confirmPassword, onCancel }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  return <Form form={form} onFinish={onSubmitWrapper} name='register' initialValues={{ remember: true }} style={{width: '40%'}}>
    <Form.Item name='email' hasFeedbackrules={[{ required: true, message: <p>Please input email</p> }]} style={{marginTop: '40px'}} hasFeedback>
      <Text>Email</Text>
      <Input prefix={<MailIcon />} placeholder={email} value={email} disabled/>
    </Form.Item>

    <Form.Item name='code' hasFeedbackrules={[{ required: true, message: <Text>Please input the confirmation code</Text> }]} style={{marginTop: '40px'}} hasFeedback>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text>Confirmation Code</Text>
      </div>
      <Input prefix={<LockIcon />} placeholder='Confirmation Code' onChange={(e) => setCode(e.target.value)} />
    </Form.Item>

    <Form.Item name='password' hasFeedbackrules={[{ required: true, message: <Text>Please input the correct password</Text> }]} style={{marginTop: '40px'}} hasFeedback>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text>Password</Text>
      </div>
      <Input prefix={<LockIcon />} placeholder='Password' type='password' onChange={(e) => setPassword(e.target.value)} />
    </Form.Item>

    <Form.Item name='confirmPassword' hasFeedbackrules={[{ required: true, message: <Text>The passwords do not match</Text> }]} style={{marginTop: '40px'}} hasFeedback>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text>Confirm password</Text>
      </div>
      <Input prefix={<LockIcon />} placeholder='Confirm password' type='password' onChange={(e) => setConfirmPassword(e.target.value)} />
    </Form.Item>

    <Form.Item style={{ marginBottom: 0 }}>
      {onCancel && <ActionButton type='primary' onClick={onCancel} />}
      <ActionButton available={email && code && password && confirmPassword} disabled={!email || !code || !password || !confirmPassword} loading={loading} type='primary' htmlType='submit' style={{ width: '100%' }}>
        Submit new password
      </ActionButton>
    </Form.Item>

  </Form>
}

export default ForgotPasswordConfirm
