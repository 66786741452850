import styled, { withTheme } from 'styled-components'
import { Col, Modal } from 'antd'

export const ListItem = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ index, theme }) => index % 2 === 0 ? 'none' : theme.colors.BACKGROUND_LIGHT};

  &:hover {
    transition: all 0.5s ease;
    background-color: rgba(0,0,0,0.2);
  }
`

export const CustomModal = withTheme(styled(Modal)`
  .ant-modal-content {
    background: ${props => props.theme.colors.BACKGROUND_LIGHTER};
  }

  .ant-modal-title {
    background: ${props => props.theme.colors.BACKGROUND_LIGHTER};
    color: ${props => props.theme.colors.FONT};
  }

  .ant-steps-item.ant-steps-item-process.ant-steps-item-active::before {
    background-color: ${props => props.theme.colors.MAIN};
    border-color: ${props => props.theme.colors.MAIN};
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: ${props => props.theme.colors.MAIN};
    border-color: ${props => props.theme.colors.MAIN};
  }
`)

export const ButtonsContainer = styled(Col)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`