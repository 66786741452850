import React, { useEffect }  from 'react'
import { Route, Switch } from 'react-router'

import AuthLayout from './pages/AuthLayout'
import Layout from './pages/Layout'

import LoginPage from './pages/Auth/Login'
import RegisterPage from './pages/Auth/Register'
import ForgotPasswordPage from './pages/Auth/ForgotPassword'
import DashboardPage from './pages/Dashboard'
import ElyziumPage from './pages/Elyzium'
import OblivionPage from './pages/Oblivion'
import ProfilePage from './pages/Profile'
import PlayerbasePage from './pages/Playerbase'
import SettingsPage from './pages/Settings'
import PluginsPage from './pages/plugins'
import DownloadMeduzaCore from './pages/DownloadMeduzaCore'
import { useUser } from 'hooks/context/user-context'

import ClientLoader from './pages/Loader'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationQuery } from 'hooks/api/organizations'
import { useConnector } from 'hooks/context/connector-context'
import { useAccount } from 'hooks/context/account-context'
import { useAccountQuery } from 'hooks/api/organizations/accounts/get-account'
import { usePurchases } from 'hooks/context/purchases-context'
import { usePurchasesQuery } from 'hooks/api/purchases'
import { useConnectorsQuery } from 'hooks/api/connectors'


const Routes = () => {
  const { authenticated, session, initialUserLoading, getCurrentSession } = useUser()
  const { setAccount } = useAccount()
  const { setPurchases } = usePurchases()
  const { setOrganization } = useOrganization()
  const { setConnector } = useConnector()

  const { data: currentAccount, isLoadingAccount } = useAccountQuery()
  const { data: currentPurchases, isLoadingPurchases } = usePurchasesQuery()
  const { data: currentOrganization, isLoadingOrganization } = useOrganizationQuery()
  const { data: currentConnector, isLoadingConnector } = useConnectorsQuery()

  const sessionExpired = !session || (Date.now() - session.accessToken.payload.exp * 1000) >= 0

  // dispatch current session call if session is expired so it gets refreshed
  useEffect(getCurrentSession, [authenticated, sessionExpired])

  useEffect(() => {
    if (isLoadingAccount || !session?.accessToken?.jwtToken || !currentAccount) return
    return setAccount(currentAccount)
  }, [currentAccount, isLoadingAccount])

  useEffect(() => {
    if (isLoadingPurchases || !session?.accessToken?.jwtToken || !currentAccount) return
    return setPurchases(currentPurchases)
  }, [currentPurchases, isLoadingPurchases])

  useEffect(() => {
    if (isLoadingOrganization || !session?.accessToken?.jwtToken || !currentOrganization || !currentAccount?.organization) return
    return setOrganization(currentOrganization)
  }, [currentOrganization, isLoadingOrganization])

  useEffect(() => {
    if (isLoadingConnector || !currentConnector || !currentOrganization) return
    return setConnector(currentConnector)
  }, [currentConnector, isLoadingConnector])

  const unauthedRoutes = () => {
    return (
      <AuthLayout>
        <Switch>
          <Route path='/meduzacore/download/MeduzaCore-2.0.jar' component={DownloadMeduzaCore} />
          <Route path='/auth/password/forgot' component={ForgotPasswordPage} />
          <Route path='/auth/register' component={RegisterPage} />
          <Route path='/auth/login' component={LoginPage} />
          <Route path='/' component={LoginPage} />
        </Switch>
      </AuthLayout>
    )
  }

  const authedRoutes = () => {
    return (
      <Layout>
        <Switch>
          <Route path='/plugins' component={PluginsPage} />
          <Route path='/settings' component={SettingsPage} />
          <Route path='/oblivion' component={OblivionPage} />
          <Route path='/elyzium' component={ElyziumPage} />
          <Route path='/playerbase/:playerUuid' component={ProfilePage} />
          <Route path='/playerbase' component={PlayerbasePage} />
          <Route path='/dashboard' component={DashboardPage} />

          <Route exact path='/' component={DashboardPage} />
        </Switch>
      </Layout>
    )
  }

  if (initialUserLoading) return <div>Loading...</div>
  return <ClientLoader>{authenticated ? authedRoutes() : unauthedRoutes()}</ClientLoader>
}

export default Routes
