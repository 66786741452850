import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useDispatch } from 'react-redux'

export const UltraWrapper = styled.div`
  display: flex;
  flex: 7;
  overscroll-behavior-y: none;
  padding: 0;
  margin: 12px;
  margin-left: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  min-height: 97.7vh;
  height: 100%;
  width: 100%;
  box-shadow: 0px 1px 20px 4px  ${({ darkTheme }) => !darkTheme ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)'};
  // background: linear-gradient(243deg, rgba(255, 255, 255, 0.37) 0%, rgba(217, 217, 217, 0.15) 100%);
  border-radius: 0px 15px 15px 0px;
`

const Settings = () => {
  const dispatch = useDispatch()
  return (
    <UltraWrapper>

    </UltraWrapper>
  )
}

export default Settings
