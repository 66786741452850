import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ORGANIZATION } from './index'
import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'
import { useAccount } from 'hooks/context/account-context'

const getOrganization = async (organization) => {
  const { data } = await apiFetch(paths.organization(organization))
  return data
}

export const useOrganizationQuery = () => {
  const { session }: any = useUser()
  const { account }: any = useAccount()
  const { setOrganization } = useOrganization()

  return useQuery(
    [ORGANIZATION, account?.organization],
    () => getOrganization(account?.organization),
    {
      staleTime: Infinity,
      enabled: !!session?.accessToken.jwtToken && !!account?.organization,
      refetchOnWindowFocus: false,
      onSuccess: async (organization) => {
        // sets value to local storage and organization context
        if (!organization) return setOrganization({})
        return setOrganization(organization)
      }
    }
  )
}
