import React, { useEffect, useState } from 'react'
import { NavLink as Link, useHistory } from 'react-router-dom'
import { Form, notification } from 'antd'

// Specialized components
import { Wrapper, HeaderTextContainer, ActionButton, MailIcon, LockIcon } from './styles'

// General components
import Input from '../../../components/Input'
import Text from 'components/text'

import { useLogin } from 'hooks/auth'
import { useUser } from 'hooks/context/user-context'

const LoginPage = ({ children }) => {
  const [form] = Form.useForm()

  const [username, setUsername] = useState()
  const [password, setPassword] = useState()

  const { mutate: login, isLoading: loadingLogin } = useLogin()
  const { authMessage: authError, setAuthMessage } = useUser()

  const loading = loadingLogin

  useEffect(() => {
    setAuthMessage(false)
  }, [])

  useEffect(() => {
    if (!authError) return
    notification.error({
      message: authError?.message || 'Something went wrong',
      placement: 'topRight',
      duration: 10
    })
  }, [authError])

  const onSubmitLogin = async () => {
    if (!username | !password) return
    login({ email: username, password })
    // history.push('/')
  }

  return (
    <Wrapper>
      <HeaderTextContainer>
        <Text type='h1'>Sign In</Text>
        <Text type='boldLabel'>to your Meduza Plugins account</Text>
      </HeaderTextContainer>

      {/* <GoogleButton><GoogleIcon /> Sign in with Google</GoogleButton>

      <Divider text='or continue with' color='#919191' /> */}

      <Form form={form} onFinish={onSubmitLogin} name='login' initialValues={{ remember: true }} style={{width: '40%'}}>
        <Form.Item name='Username' hasFeedbackrules={[{ required: true, message: <p>Please enter your username</p> }]} hasFeedback>
          <Text>Email</Text>
          <Input prefix={<MailIcon />} placeholder='Email' onChange={(e) => setUsername(e.target.value)} />
        </Form.Item>

        <Form.Item name='password' hasFeedbackrules={[{ required: true, message: <Text>Please input the correct password</Text> }]} style={{marginTop: '50px'}} hasFeedback>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Text>Password</Text>
            <Link to='/auth/password/forgot' style={{margin: 0, padding: 0}}><Text type='label' clickable>Forgot password?</Text></Link>
          </div>
          <Input prefix={<LockIcon />} placeholder='Password' type='password' onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0, marginTop: 0, }}>
          <ActionButton available={username && password} loading={loading} htmlType='submit' type='primary' style={{ width: '100%' }}>Login</ActionButton>
        </Form.Item>
      </Form>
      <div style={{display: 'flex'}}>
        <Text type='mini' style={{marginRight: '5px'}}>Not registered yet?</Text> 
        <Link to='/auth/register'>
          <Text type='mini' clickable>Create an account</Text>
        </Link>
      </div>
    </Wrapper>
  )
}

export default LoginPage
