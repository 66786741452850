export const VERIFICATION = 'verification'

export * from './get-verifications'
export * from './create-verification'

export const verificationPaths = {
  filter: (path, query) => `${path}?${query}`,

  account: (identity) => `/accounts/${identity}`,
  base: (account) => `/accounts/${account}/verifications`,

  verifications: (account) => `${verificationPaths.base(account)}/verifications`,
  verification: (account, identity) => `${verificationPaths.verifications(account)}/${identity}`
}
