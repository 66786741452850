import { Segmented } from 'antd'
import styled, { withTheme } from 'styled-components'

export const UltraWrapper = styled.div`
  box-sizing: border-box;

  display: flex;
  gap: 15px;
  overscroll-behavior-y: none;
  padding: 0;
  margin: 12px;
  padding: 20px 30px;

  width: 100%;
`

export const CustomSegment = withTheme(styled(Segmented)`
  :where(.css-dev-only-do-not-override-1n7nwfa).ant-segmented {
    padding: 0;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.5);
  }

  .ant-segmented-item {
    transition: ${props => props.theme.colors.BACKGROUND} 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-segmented-group {
    background-color: ${props => props.theme.colors.BACKGROUND_LIGHTER};
    color: ${props => props.theme.colors.FONT};
  }

  .ant-segmented-item-selected {
    background-color: ${props => props.theme.colors.BACKGROUND};
    color: ${props => props.theme.colors.FONT};
  }

  .ant-segmented-thumb {
    background-color: ${props => props.theme.colors.BACKGROUND};
  }
`)

export const StepsWrapper = withTheme(styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  background: ${props => props.theme.colors.BACKGROUND};

  border-radius: 15px;
  margin-top: 20px;

  height: 70%;
`)

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 10px 20px;
`

export const VerificationCardContainer = withTheme(styled.div`
  display: flex;
  flex-direction: column;

  height: 250px;
  width: 300px;

  background: ${props => props.theme.colors.BACKGROUND};

  border-radius: 15px;
  padding: 10px;
  padding-bottom: 15px;
`)