import styled from 'styled-components'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Card from '../Card'

// General components
import Button from 'components/Button'
import Text from 'components/text'

// Onboarding - Create
import CreateOrgStepOne from './CreateOrganizationSteps/CreateOrgStepOne'

import { useCreateOrganizationsQuery, useOrganizationQuery } from 'hooks/api/organizations'
import { useOrganization } from 'hooks/context/organization-context'
import { useCreateConnectorQuery } from 'hooks/api/connectors'

import { Wrapper, CardsContainer, Container, Next, Back } from './styles'
import { useAccount } from 'hooks/context/account-context'
import CustomButton from 'components/Button'

const Onboarding = () => {
  const history = useHistory()
  const { organization: currentOrganization } = useOrganization()
  const { account } = useAccount()

  const [onboardingType, setOnboardingType] = useState()
  const [step, setStep] = useState(1)

  const [organizationName, setOrganizationName] = useState()

  const { mutateAsync: createOrganization, isLoading: createOrganizationLoading, error: createOrganizationError } = useCreateOrganizationsQuery()
  const { mutateAsync: createConnector, isLoading: createConnectorLoading, error: createConnectorError } = useCreateConnectorQuery()

  const goNextConditions = [null, !isEmpty(organizationName) && !createOrganizationLoading && !currentOrganization]
  const clearState = () => {
    setOnboardingType(null)
    setStep(1)
    setOrganizationName(null)
  }

  const steps = {
    CREATE: { 
      1: {
        title: 'Organization info',
        body: <CreateOrgStepOne setOrganizationName={setOrganizationName} organizationName={organizationName} />,
        backButton: <Back onClick={() => clearState()} style={{ marginRight: 'auto' }} />,
        nextButton: <CustomButton onClick={async () => { if (goNextConditions[1]) { await createOrganization({ name: organizationName, account: account.id }); } }} disabled={!goNextConditions[1]} style={{ marginLeft: 'auto', width: '40%', marginRight: 'auto' }} title='Create Organization' clickable/>,
      },
    },
    JOIN: []
  }

  let Content

  // if (loading) return <div>Loading yo</div>

  if (onboardingType === 'CREATE') {
    Content = (
      <Wrapper>
        {/* {steps['CREATE'][step]?.backButton} */}
        <Container>
          {steps['CREATE'][step]?.body}
          {steps['CREATE'][step]?.nextButton}
        </Container>
        {/* {steps['CREATE'][step]?.nextButton} */}
      </Wrapper>
    )
  } else if (onboardingType === 'JOIN') {
    Content = <div>JOIN</div>
  } else {
    Content = (
      <Wrapper>
        <Container>
          <Text type='h1' style={{width: '80%', textAlign: 'center'}}>Are you creating a group, or joining an existing one?</Text>
          <Text type='h5' style={{width: '75%', textAlign: 'center'}}>Creating a group requires for you to own one of Meduza's plugins. You can also join an existing group, given that someone else owns the plugin and lets you join their group. (To join another group, you need to be invited by the group owner)</Text>
          <CardsContainer>
            <Card text='Create an organization' onClick={() => setOnboardingType('CREATE')}></Card>
          </CardsContainer>
        </Container>
      </Wrapper>)
  }

  return Content
}

export default Onboarding
