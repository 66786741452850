import styled, { useTheme } from 'styled-components'
import { COLORS } from '../../../lib/constants/colors'

import Text from 'components/text'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  height: 12rem;
  width: 20rem;
  margin-right: 5%;

  border-radius: 10px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);

  transition: 0.8s ease;
  &:hover {
    cursor: pointer;
    box-shadow: 3px 3px 40px 5px rgba(0,0,0,0.5);

    // background-color: ${COLORS.SECONDARY_300};
  }
`

export const StyledItem = styled.div`
  border-right: 20px solid ${({ ok }) => ok ? COLORS.SUCCESS_300 : COLORS.ERROR_200 };
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;

  border-radius: 150px;
  height: 100px;

  // box-shadow: 0 0 10px ${COLORS.SECONDARY_300};
  // box-shadow: 0 20px 10px -17px rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 0px 20px ${({ ok }) => ok ? COLORS.SUCCESS_300 : COLORS.ERROR_300 });
`

const MetricCard = ({ children, title, onClick, icon, subtitle, value, secondaryValue, ok }: any) => {
  const theme = useTheme()

  return (
    <Wrapper onClick={onClick}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', paddingBottom: 0, marginBottom: 0}}>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '100%' }}>
          {icon}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 20px', paddingTop: '5px', width: '100%', height: '100%' }}>
          <Text type='boldLabel'>{title}</Text>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <Text type='h2' color={theme.colors.MAIN} style={{ marginBottom: '10px' }}>{value}</Text> <h3 style={{marginLeft: '5px', color: COLORS.SUCCESS_100}}>{secondaryValue ? `(${secondaryValue})` : null}</h3>
          </div>
          <Text type='mini'>{subtitle}</Text>

        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 0, margin: 0, height: '100%', width: '25px' }}>
        <StyledItem ok={ok}/>
      </div>
    </Wrapper>
  )
}

export default MetricCard
