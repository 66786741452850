import { omitBy, isUndefined } from 'lodash'
import { Auth } from 'aws-amplify'

class FetchError extends Error {
  constructor(obj) {
    super()
    this.detail = obj.detail
    this.code = obj.code
    this.status = obj.status
    this.json = obj.json
  }
}

export const waitingState = (key, state) => {
  return {
    type: 'WAITING',
    payload: {
      key, state: !!state
    }
  }
}

export const getActionSuccessKey = (key, id) => {
  return `${key}_${id}`
}

export const getActionErrorKey = (key, id) => {
  return `${key}_${id}_ERROR`
}

export const getDispatch = (request, successKey, errorKey, id, params, clearKey) => async (dispatch) => {
  try {
    dispatch(waitingState(successKey, true))
    if (clearKey) await dispatch({ type: clearKey })

    const data = await request

    return dispatch({
      type: successKey,
      payload: id ? { id: id, data: data } : data,
      ...params
    })
  } catch (e) {
    return dispatch({
      type: errorKey,
      payload: e
    })
  } finally {
    dispatch(waitingState(successKey, false))
  }
}

export const fetchJSON = async function () {
  const args = Array.from(arguments)

  const res = await global.fetch.apply(this, args)
  try {
    let json = await res.json()
    // if (res.status !== 204 && res.status !== 404) {
    //   json = await res.json()
    // }
    if (!res.ok) {
      throw new FetchError({ json, status: res.status })
    }
    return { json, status: res.status }
  } catch (error) {
    throw new FetchError({ ...error })
  }
}

export const apiFetch = async function (path, ...rest) {
  const args = {
    method: 'GET',
    headers: new global.Headers(),
    ...rest[0]
  }

  // Set content type it none supplied
  if (!args.headers.get('Content-Type')) {
    args.headers.set('Content-Type', 'application/json')
  }

  // Set authorization if cognito token exists and none supplied
  try {
    const { accessToken } = await Auth.currentSession()
    if (accessToken && !args.headers.get('Authorization')) {
      args.headers.set('Authorization', `Bearer ${accessToken.jwtToken}`)
    }
  } catch (e) {
    console.log('Amplify error: ', e)
  }

  const url = 'https://y4nkkg1m3e.execute-api.us-east-1.amazonaws.com/prod/v1' + path
  const fetch = () => fetchJSON.apply(this, [url, { ...args }])

  try {
    const { json: result } = await fetch()
    return result
  } catch (error) {
    if (error.status === 502 || error.status === 503) {
      const { json: result } = await fetch()
      return result
    }
    throw error
  }
}

export const omitEmpty = function (data) {
  return omitBy(data, val => isUndefined(val) || val === null)
}