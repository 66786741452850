import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { Drawer, Skeleton, Divider, List, Popover, Empty } from 'antd'
import styled, { withTheme, useTheme } from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { orderBy } from 'lodash'

import { VscNote, VscSettingsGear } from 'react-icons/vsc'
import Text from 'components/text'

export const Reason = withTheme(styled(VscNote)`
  font-size: 1.2rem;
  padding: 0px;
  color: ${props => props.theme.colors.FONT};
`)

export const Edit = withTheme(styled(VscSettingsGear)`
  font-size: 1.2rem;
  padding: 0px;
  color: ${props => props.theme.colors.FONT};
`)

const CustomPopover = withTheme(styled(Popover)`
  .ant-popover-arrow-content::before {
    background-color: ${props => props.theme.colors.BACKGROUND};
  }
`)

const BanDrawer = ({ open, onClose, bans = [] }) => {
  const theme = useTheme()
  const history = useHistory()

  const statusPopover = (ban) => {
    switch (ban.status) {
    default: return null
    case 'OVERRIDDEN': return <Text type='h5'>{'Overridden'}</Text>
    // case 'OVERRIDDEN': return <Text type='h5' color={theme.colors.MAIN} onClick={() => history.push(`/playerbase/${ban.punisherUuid}`)} clickable>{'Overridden'}</Text>
    case 'REVOKED': return (
      <CustomPopover 
        getTooltipContainer={(trigger) => trigger}
        overlayInnerStyle={{background: theme.colors.BACKGROUND}}
        content={
            <div style={{display: 'flex', flexDirection: 'column', background: theme.colors.BACKGROUND, padding: 0, margin: 0}}>
              <Text type='p'>Revoked At: {dayjs(parseInt(ban.revokedAt)).format('HH:mm - DD MMM \'YY')}</Text>
              <div style={{display: 'flex'}}>
                <Text type='p' style={{marginRight: '5px'}}>{'Revoked By:'}</Text>
                <Text type='p' onClick={() => history.push(`/playerbase/${ban.revokedByUuid}`)} clickable> {ban.revokedByName}</Text>
              </div>
            </div>
        }
      >
        <Text type='h5' color={theme.colors.MAIN}>{'Revoked'}</Text>
      </CustomPopover>)
    } 
  }
  
  return (
    <Drawer placement='right' closable={false} onClose={onClose} open={open} drawerStyle={{boxSizing: 'border-box', background: theme.colors.BACKGROUND_LIGHTER, color: theme.colors.FONT, overscrollBehaviorY: 'none', height: '100%'}} width='60%'>
      {/* <Text type='h5'>Historical Bans</Text> */}
      <div
        id='scrollableDiv'
        style={{
          height: '100%',
          overflow: 'auto',
          padding: '0 16px',
          // border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        <InfiniteScroll
          dataLength={bans?.length}
          next={() => [...Array(10).keys()]}
          hasMore={false ?? bans?.length < 50}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={bans?.length > 0 && <Divider plain style={{ color: theme.colors.FONT }}>No more punishments to display 🤐</Divider>}
          scrollableTarget='scrollableDiv'
        >
          <List
            locale={{ emptyText: <Empty description='No bans found for this player' style={{ color: theme.colors.FONT }} /> }}
            dataSource={orderBy(bans, 'createdAt', 'desc')}
            renderItem={(ban: any) => (
              <List.Item style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{display: 'flex', justifyContent: 'center', marginRight: '20px' }}>
                  <div style={{background: ban.status === 'ACTIVE' ? theme.colors.MAIN : theme.colors.ERROR_100, borderRadius: '15px', width: '10px', height: '10px' }}></div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginRight: '20px', width: '15%' }}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text type='h4'>{dayjs(parseInt(ban.createdAt)).format('DD MMM \'YY')}</Text>
                    <Text type='mini'>{dayjs(parseInt(ban.createdAt)).format('HH:mm')}</Text>
                  </div>
                </div>
                <div style={{display: 'flex', borderLeft: '0.1px solid white', borderRight: '0.1px solid white', paddingRight: '20px', paddingLeft: '20px', marginRight: '20px', justifyContent: 'center', width: '15%' }}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text type='mini'>{'Banned by'}</Text>
                    <Text type='h5' color={theme.colors.MAIN} onClick={() => history.push(`/playerbase/${ban.punisherUuid}`)} clickable>{ban.punisherName}</Text>
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginRight: '20px', width: '15%' }}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text type='mini'>{'Expiration'}</Text>
                    {parseInt(ban?.expiresAt) === -1 ? <Text type='h5'>{'Never'}</Text> :
                      (<><Text type='h5'>{dayjs(parseInt(ban.expiresAt)).format('DD MMM \'YY')}</Text>
                      <Text type='mini'>{dayjs(parseInt(ban.expiresAt)).format('HH:mm')}</Text></>)}
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', borderLeft: '0.1px solid white', borderRight: '0.1px solid white', paddingRight: '20px', paddingLeft: '20px', marginRight: '20px', width: '15%' }}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text type='mini'>{'Server'}</Text>
                    <Text type='h5'>{ban.server}</Text>
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', borderRight: '0.1px solid white', paddingRight: '20px', marginRight: '20px', height: '100%', width: '15%' }}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text type='mini'>{'Status'}</Text>
                    {statusPopover(ban)}
                  </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '10%', justifyContent: 'space-around' }}>
                  <CustomPopover 
                    getTooltipContainer={(trigger) => trigger}
                    overlayInnerStyle={{background: theme.colors.BACKGROUND}}
                    content={<Text type='p'>{ban.reason}</Text>}
                  >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', padding: '10px'}}><Reason /></div>
                  </CustomPopover>
                  <Edit />
                </div>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </Drawer>
  )
}

export default BanDrawer 
