import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { GeistProvider, Themes, CssBaseline } from '@geist-ui/core'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'

import configureStore, { history } from './store'
import SetupAmplify from './lib/setup-amplify'

import { UserProvider } from 'hooks/context/user-context'

import Routes from './routes'
import { COLORS } from './styles/colors'
import { auroraDark, auroraLight, GlobalStyles } from './styles/theme'

import './index.css'
import { OrganizationProvider } from 'hooks/context/organization-context'
import { ConnectorProvider } from 'hooks/context/connector-context'
import { AccountProvider } from 'hooks/context/account-context'
import { PurchasesProvider } from 'hooks/context/purchases-context'

const queryClient = new QueryClient()
const store = configureStore(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

SetupAmplify()

const geistLight = Themes.createFromLight({
  type: 'geistLight',
  palette: {
    background: COLORS.WHITE_100
  },
})

const geistDark = Themes.createFromDark({
  type: 'geistDark',
  palette: {
    foreground: COLORS.DARK_BACKGROUND
  },
})

const App = ({ hideLoader }) => {
  const isLightTheme = false

  return (
    <UserProvider>
      <AccountProvider>
        <PurchasesProvider>
          <OrganizationProvider>
            <ConnectorProvider>
              <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={isLightTheme ? auroraLight : auroraDark}>
                  <GlobalStyles />
                  <GeistProvider themes={[geistLight, geistDark]} themeType={isLightTheme ? 'geistLight' : 'geistDark' }>
                    <CssBaseline />
                    <Provider store={store}>
                      <ConnectedRouter history={history}>
                        <Routes />
                      </ConnectedRouter>
                    </Provider>
                  </GeistProvider>
                </ThemeProvider>
              </QueryClientProvider>
            </ConnectorProvider>
          </OrganizationProvider>
        </PurchasesProvider>
      </AccountProvider>
    </UserProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
