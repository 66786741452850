import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { ACCOUNT, organizationsPaths as paths } from '../index'
import { useUser } from 'hooks/context/user-context'
import { useAccount } from 'hooks/context/account-context'
import { useAccountQuery } from './get-account'

const updateAccount = async (accountId, payload) => {
  const { data } = await apiFetch(paths.account(accountId), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateAccountQuery = () => {
  const queryClient = useQueryClient()
  const { refetch } = useAccountQuery()

  const { refreshCurrentSession } = useUser()
  const { account, setAccount }: any = useAccount()

  return useMutation(
    (payload) => updateAccount(account?.id, payload),
    {
      onMutate: (payload: any) => {
        const prevAccount: any = queryClient.getQueryData([ACCOUNT])

        const newAccount = { ...prevAccount, ...payload }
        setAccount(newAccount)
        queryClient.setQueryData([ACCOUNT], newAccount)

        return { prevAccount }
      },
      onError: (err, newItem, context: { prevAccount: any }) => {
        if (err) queryClient.setQueryData([ACCOUNT], context.prevAccount)
      },
      onSuccess: () => {
        refreshCurrentSession(refetch)
      }
    }
  )
}
