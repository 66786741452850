import React from 'react'
import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, message } from 'antd'
import { isEmpty, isNil, omitBy } from 'lodash'

import Text from 'components/text'
import CustomInput from 'components/Input'

import { useConnector } from 'hooks/context/connector-context'
import { useCreateConnectorQuery, useUpdateConnectorQuery } from 'hooks/api/connectors'
import { useOrganization } from 'hooks/context/organization-context'

import { Asterix, CategoryDivider, FormItemContainer, SpecialText } from './styles'

const PluginsTab = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [updateConnectorForm] = Form.useForm()
  const [createConnectorForm] = Form.useForm()

  const { organization } = useOrganization()
  const { connector } = useConnector()

  const { mutateAsync: createConnector, isLoading: createConnectorLoading } = useCreateConnectorQuery()
  const { mutateAsync: updateConnector, isLoading: updateConnectorLoading } = useUpdateConnectorQuery()

  const createFormFinish = async (values) => {
    const { newDatabaseHost, newDatabaseName, newDatabaseUsername, newDatabasePassword } = values
    if (!newDatabaseHost || !newDatabaseName || !newDatabaseUsername || !newDatabasePassword) return

    try {
      const response = await createConnector(omitBy({ mysqlHost: newDatabaseHost, mysqlDatabase: newDatabaseName, mysqlUsername: newDatabaseUsername, mysqlPassword: newDatabasePassword }, isNil))

      if (!response) {
        message.error('Something went wrong and couldn\'t update the details for your Database', 5)
        return
      }
      
      message.success('Successfully updated Database connection details', 5)

      createConnectorForm.resetFields()
    } catch (e) {
      message.error('Something went wrong and couldn\'t update the details for your Database', 5)
    }
  }

  const updateFormFinish = async (values) => {
    if (isEmpty(values)) return
    const { newDatabaseHost, newDatabaseName, newDatabaseUsername, newDatabasePassword } = values

    try {
      const response = await updateConnector(omitBy({ mysqlHost: newDatabaseHost, mysqlDatabase: newDatabaseName, mysqlUsername: newDatabaseUsername, mysqlPassword: newDatabasePassword }, isNil))

      if (!response) {
        message.error('Something went wrong and couldn\'t update the details for Elyzium', 5)
        return
      }
      
      message.success('Successfully updated Elyzium connection details', 5)

      updateConnectorForm.resetFields()
    } catch (e) {
      message.error('Something went wrong and couldn\'t update the details for Elyzium', 5)
    }
  }

  return (<div>
    <Text type='h3'>Plugins</Text>
    <Text type='label'>Manage your plugin connections' information here</Text>

    {connector ? <>
      <CategoryDivider />
      <Form name='updateConnectorForm' form={updateConnectorForm} onFinish={updateFormFinish}>
        <FormItemContainer>
          <SpecialText type='label'>Database Host <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabaseHost'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder={connector?.mysqlHost} />
          </Form.Item>
        </FormItemContainer>
        
        <FormItemContainer>
          <SpecialText type='label'>Database Name <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabaseName'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder={connector?.mysqlDatabase} />
          </Form.Item>
        </FormItemContainer>

        <FormItemContainer>
          <SpecialText type='label'>Database Username <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabaseUsername'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder={connector?.mysqlUsername} />
          </Form.Item>
        </FormItemContainer>

        <FormItemContainer>
          <SpecialText type='label'>Database Password <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabasePassword'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder='****' password />
          </Form.Item>
        </FormItemContainer>
        
        <FormItemContainer>
          <SpecialText type='label'></SpecialText>
          <Form.Item>
            <Button htmlType='submit' loading={updateConnectorLoading} style={{ marginTop: '20px', backgroundColor: theme.colors.MAIN, color: theme.colors.FONT_INVERSE, border: 'none'}} type='primary' >Update Database Connection Info</Button>
          </Form.Item>
        </FormItemContainer>
      </Form>
    </> : <>
      <CategoryDivider />
      <Form name='createConnectorForm' form={createConnectorForm} onFinish={createFormFinish}>
        <FormItemContainer>
          <SpecialText type='label'>Database Host <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabaseHost'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder={connector?.mysqlHost || 'host'} />
          </Form.Item>
        </FormItemContainer>
        
        <FormItemContainer>
          <SpecialText type='label'>Database Name <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabaseName'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder={connector?.mysqlDatabase || 'database'} />
          </Form.Item>
        </FormItemContainer>

        <FormItemContainer>
          <SpecialText type='label'>Database Username <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabaseUsername'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder={connector?.mysqlUsername || 'username'} />
          </Form.Item>
        </FormItemContainer>

        <FormItemContainer>
          <SpecialText type='label'>Database Password <Asterix>*</Asterix></SpecialText>
          <Form.Item name='newDatabasePassword'>
            <CustomInput style={{width: '300px', height: '50px'}} placeholder='****' password />
          </Form.Item>
        </FormItemContainer>
        
        <FormItemContainer>
          <SpecialText type='label'></SpecialText>
          <Form.Item>
            <Button htmlType='submit' loading={createConnectorLoading} style={{ marginTop: '20px', backgroundColor: theme.colors.MAIN, color: theme.colors.FONT_INVERSE, border: 'none'}} type='primary' >Update Database Connection Info</Button>
          </Form.Item>
        </FormItemContainer>
      </Form>
    </>
    }
  </div>)
}

export default PluginsTab
