import styled, { withTheme } from 'styled-components'

type Props = {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'label' | 'boldLabel' | 'largeNumber' | 'statNumber' | 'mini' | 'miniBold'
  size?: string
  ghost?: boolean
  color?: string
  clickable?: boolean
  capitalize?: boolean
  children?: unknown
  style?: unknown
  className?: unknown
}

const StyledText = withTheme(styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  color: ${({ theme }) => theme.colors.FONT};

  ${props => props.size && `font-size: ${props.size};`}
  ${props => props.textTransform && 'text-transform: uppercase;'}
  ${props => props.ghost && `color: ${props.theme.colors.GHOST};`}
  ${props => props.capitalize && 'text-transform: uppercase;' }
  ${props => props.clickable && `
    color: ${props.theme.colors.CLICKABLE};
    
    transition: all 0.5s ease;
    &:hover {
      cursor: pointer;
      color: ${props.theme.colors.CLICKABLE_HOVER};
    }
  `}
  ${props => props.color && `color: ${props.color};`}

  margin: 0;
  padding: 0;

  ${props => props.inverse && `color: ${props.theme.colors.FONT_INVERSE};`}
`)

const calculateValues = (props: Props) => {
  switch (props.type) {
    case 'h1': return {
      fontFamily: 'Beaufort-Bold',
      fontSize: '53px',
      lineHeight: '56px',
      capitalize: true
    }
    case 'h2': return {
      fontFamily: 'Beaufort-Bold',
      fontSize: '37px',
      lineHeight: '43px',
      capitalize: true
    }
    case 'h3': return {
      fontFamily: 'Beaufort-Bold',
      fontSize: '31px',
      lineHeight: '37px',
      capitalize: true
    }
    case 'h4': return {
      fontFamily: 'Beaufort-Bold',
      fontSize: '24px',
      lineHeight: '29px',
      capitalize: true
    }
    case 'h5': return {
      fontFamily: 'Beaufort-Bold',
      fontSize: '19px',
      lineHeight: '24px',
      capitalize: true
    }
    case 'p': return {
      fontFamily: 'Beaufort',
      fontSize: '19px',
      lineHeight: '27px',
    }
    case 'boldLabel': return {
      fontFamily: 'Spiegel-Bold',
      fontSize: '15px',
      lineHeight: '21px',
    }
    case 'label': return {
      fontFamily: 'Spiegel',
      fontSize: '15px',
      lineHeight: '21px',
    }
    case 'mini': return {
      fontFamily: 'Spiegel',
      fontSize: '13px',
      lineHeight: '15px',
    }
    case 'miniBold': return {
      fontFamily: 'Spiegel-Bold',
      fontSize: '13px',
      lineHeight: '15px',
    }
    case 'largeNumber': return {
      fontFamily: 'Spiegel-Bold',
      fontSize: '76px',
      lineHeight: '83px',
    }
    case 'statNumber': return {
      fontFamily: 'Beaufort-Bold',
      fontSize: '19px',
      lineHeight: '27px',
    }
    default: return {
      fontFamily: 'Beaufort-Bold',
      fontSize: '19px',
      lineHeight: '27px',
    }
  }
}

const Text = (props: Props) => {

  return (
    <StyledText 
      {...calculateValues(props)} 
      {...(props.color && { color: props.color })}
      {...(props.capitalize && { capitalize: props.capitalize })} 
      {...(props.ghost && { ghost: props.ghost })}
      {...(props.clickable && { clickable: props.clickable })}
      {...(props.size && { size: props.size })}
      style={props.style}
      className={props.className}
      {...props}
    >{props.children}</StyledText>
  )
}

export default withTheme(Text)
