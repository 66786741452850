import { Divider } from 'antd'
import styled, { withTheme } from 'styled-components'

import Text from 'components/text'

export const Asterix = withTheme(styled.span`
  color: ${props => props.theme.colors.ERROR_300};
`)

export const CategoryDivider = withTheme(styled(Divider)`
  background-color: ${props => props.theme.colors.FONT};
  margin: 30px 0;
`)

export const FormItemContainer = withTheme(styled.div`
  display: flex;

  width: 100%;
  align-items: center;
  margin-bottom: 0;
`)

export const SpecialText = styled(Text)`
  width: 200px;
  margin-right: 5%;
`
