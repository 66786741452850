import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { orderBy } from 'lodash'
import { Skeleton, Divider, List, Empty } from 'antd'
import styled, { useTheme } from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import { VscClose } from 'react-icons/vsc'

import { useElyziumTicketRepliesByIdQuery } from 'hooks/api/connectors'
import Text from 'components/text'

dayjs.extend(relativeTime)

export const CloseComments = styled(VscClose)`
  box-sizing: border-box;

  font-size: 1.5rem;
  margin: 0 10px;
  padding: 0;
  color: ${props => props.theme.colors.FONT};

  &:hover {
    cursor: pointer;
  }
`


const Container = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  width: 50%;
  height: 100%;

  padding: 15px 10px;

  background: ${props => props.theme.colors.BACKGROUND_LIGHT};
  border-radius: 10px;

  transition: all 0.5s ease;

  display: ${props => props.visible ? 'block' : 'none'};
`

const ListItem = styled(List.Item)`
  &::before {
    display: none;
  }
`

const CommentsList = ({ setComments, visible, ticket }) => {
  const theme = useTheme()

  const { data: comments }: any = useElyziumTicketRepliesByIdQuery(ticket?.id)

  return (
    <Container visible={visible}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
        <Text type='h4'>Comments {comments?.length ? `(${comments?.length})` : ''}</Text>
        <CloseComments onClick={() => setComments(null)}/>
      </div>
      <InfiniteScroll
        dataLength={comments?.length || 0}
        next={() => [...Array(10).keys()]}
        hasMore={false ?? comments?.length < 50}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={comments?.length > 0 && <Divider plain style={{ color: theme.colors.FONT }}>No more comments to display 🤐</Divider>}
        scrollableTarget='scrollableDiv'
      >
        <List
          locale={{ emptyText: <Empty description='No comments found for this ticket' style={{ color: theme.colors.FONT }} /> }}
          dataSource={orderBy(comments, 'createdAt', 'desc')}
          renderItem={(comment: any) => (
            <ListItem style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', background: theme.colors.BACKGROUND, border: 'none', borderRadius: '5px', padding: '15px', gap: '10px' }}>
              <div style={{display: 'flex', width: '100%', gap: '5px'}}>
                <Text type='boldLabel'>{comment?.createdByName}</Text>
                <Text type='boldLabel'>·</Text>
                <Text type='label'>{dayjs(parseInt(comment.createdAt)).fromNow()}</Text>
              </div>
              <div style={{display: 'flex', width: '100%'}}>
                <Text type='label'>{comment.reply}</Text>
              </div>
            </ListItem>
          )}
        />
      </InfiniteScroll>
    </Container>
  )
}

export default CommentsList
