import React, { useState, useEffect } from 'react'
import { Badge } from 'antd'
import { isEmpty, isNil, omitBy } from 'lodash'
import styled, { useTheme } from 'styled-components'

import { Gradient } from './Gradient'

import Text from 'components/text'

import defaultIcon from './default-icon.jpg'
import './styles.css'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 10rem;
  width: 20rem;
  margin-right: 5%;

  border-radius: 10px;

  border: 0.1px solid #1E2328;

  transition: 0.5s ease;

  &:hover {
    cursor: pointer;
    // box-shadow: 1px 1px 10px 4px red;
  }
`

const GlowText = styled(Text)`
  ${props => props.online ?
  `
    text-shadow: 0 0 7px ${props.theme.colors.SUCCESS_300};
    color: ${props.theme.colors.FONT_INVERSE};
  ` :
  `
    text-shadow: 0 0 7px ${props.theme.colors.ERROR_300};
    color: ${props.theme.colors.FONT_INVERSE};
  `}
`


const ServerCard = ({ server, onClick, index }: any) => {
  const theme = useTheme()
  const [serverWithData, setServersWithData] = useState({})

  const request = async (path, server) => fetch(`${path}${server?.ip}`, { headers: new Headers().append('Access-Control-Allow-Origin', '*')  })

  useEffect(async () => {
    if (!server) return

    const gradient = new Gradient()
    gradient.initGradient(`#gradient-canvas-${index}`)

    const response = await request('https://api.mcsrvstat.us/2/', server)
    const resultObject = await response.text()

    const resultingServer = omitBy({ name: server.name, ip: server.ip, metadata: JSON.parse(resultObject)}, isNil)

    if (isEmpty(serverWithData)) setServersWithData(server)
    setServersWithData(resultingServer)
  }, [server])

  return (
    <Wrapper onClick={onClick} online={serverWithData?.metadata?.online}>
      <div style={{zIndex: 10, display: 'flex', width: '90%', justifyContent: 'space-between', padding: '10px 10px'}}>
        <img src={serverWithData?.metadata?.icon || defaultIcon} style={{width: '50px', height: '50px', marginRight: '10px'}}/>
        <GlowText type='label' online={serverWithData?.metadata?.online} inverse>{serverWithData?.metadata?.online ? 'Online ' : 'Offline '}<Badge status={serverWithData?.metadata?.online ? 'success' : 'error'} /></GlowText>
      </div>
      <div style={{zIndex: 10, display: 'flex', width: '90%', padding: '0 10px'}}>
        <Text type='h5' inverse>{serverWithData?.name}</Text>
      </div>
      <div style={{zIndex: 10, display: 'flex', width: '90%', padding: '0 10px', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Text type='mini' inverse style={{bottom: 0}}>Users Online</Text>
          <Text type='boldLabel' inverse>{serverWithData?.metadata?.players?.online || 0}/{serverWithData?.metadata?.players?.max || 0}</Text>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Text type='mini' inverse style={{bottom: 0}}>Server Version</Text>
          <Text type='boldLabel' inverse>{serverWithData?.metadata?.version}</Text>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Text type='mini' inverse style={{bottom: 0}}>Software</Text>
          <Text type='boldLabel' inverse>{serverWithData?.metadata?.software}</Text>
        </div>
      </div>

      <canvas id={`gradient-canvas-${index}`} className='gradient-canvaz' style={{borderRadius: '10px', padding: 0, margin: 0}}/>
    </Wrapper>
    
  )
}

export default ServerCard
