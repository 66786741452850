import styled, { withTheme } from 'styled-components'
import { Button } from 'antd'

enum ButtonTypes {
  Default = 'default',
  Primary = 'primary'
}

const getBackgroundColor = (props) => {
  if (props.disabled) return props.theme.colors.DISABLED

  if (props.btnType === ButtonTypes.Default) return props.theme.colors.BACKGROUND_LIGHT
  else return props.theme.colors.MAIN_DARK
}

const getColor = (props) => {
  if (props.disabled) return props.theme.colors.FONT

  if (props.btnType === ButtonTypes.Default) return props.theme.colors.FONT
  else return props.theme.colors.FONT_INVERSE
}

const getHoverBackgroundColor = (props) => {
  if (props.disabled) return props.theme.colors.DISABLED

  if (props.btnType === ButtonTypes.Default) return props.theme.colors.BACKGROUND_LIGHTER
  else return props.theme.colors.MAIN_DARKER
}

const getHoverColor = (props) => {
  if (props.disabled) return props.theme.colors.FONT

  if (props.btnType === ButtonTypes.Default) return props.theme.colors.FONT_LITE
  else return props.theme.colors.FONT_INVERSE_LITE
}


export const StyledButton = withTheme(styled(Button)`
  && {
    cursor: default;
    border: none;
    box-shadow: none;
    white-space: normal;

    width: fit-content;
    height: fit-content;

    background-color: ${props => getBackgroundColor(props)};
    color: ${props => getColor(props)};

    &&:hover {
      cursor: ${props => !props.clickable ? 'default' : props.disabled ? 'not-allowed' : 'pointer'};
      background-color: ${props => getHoverBackgroundColor(props)};
      color: ${props => getHoverColor(props)};
    }
  }
`)
