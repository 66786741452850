import styled, { useTheme } from 'styled-components'

import Text from 'components/text'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 20rem;
  width: 17rem;
  
  border-radius: 10px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);

  background-color: ${props => props.theme.colors.BACKGROUND};

  margin-top: 20px;
  margin-bottom: 20px;

  transition: 0.5s ease;
  &:hover {
    cursor: pointer;
    box-shadow: 3px 3px 20px 5px rgba(0,0,0,0.5);
  }
`

export const AvatarContainer = styled.div`
  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;

  padding: 10px 0;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 0 10px;
`

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
`

export const ElyziumContainer = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;

  width: 100%;
`

export const ElyziumStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  padding: 0 10px;
`


const validateResponse = (response) => {
  if (!response.ok) throw Error(response.statusText)
  return response
}

const PlayerCard = ({ userData, elyziumStats, onClick }: any) => {
  const theme = useTheme()

  const [avatar, setAvatar] = useState()

  const fetchAvatar = async (uuid) => fetch(`https://crafatar.com/avatars/${uuid}`).then(validateResponse).then(response => response.blob()).then(blob => setAvatar(URL.createObjectURL(blob) as any))
  useEffect(() => {
    if (!userData) return
    fetchAvatar(userData?.uuid)
  }, [userData])

  const ElyziumStats = () => (
    <ElyziumContainer>
      <ElyziumStatContainer>
        <Text type='statNumber'>{elyziumStats.punishments.length}</Text>
        <Text type='mini' color={theme.colors.GHOST}>Punishments</Text>
      </ElyziumStatContainer>

      <ElyziumStatContainer>
        <Text type='statNumber'>{elyziumStats.warnings.length}</Text>
        <Text type='mini' color={theme.colors.GHOST}>Warnings</Text>
      </ElyziumStatContainer>
    </ElyziumContainer>
  )

  return (
    <Container onClick={onClick}>
      <AvatarContainer>
        <Avatar src={avatar} alt='Player avatar' />
      </AvatarContainer>
      <DetailsContainer>
        <Text type='p' color={theme.colors.MAIN}>{userData?.name}</Text>
        <Text type='mini'>Last Logged In: {dayjs(userData?.lastLogin).toString()}</Text>
      </DetailsContainer>
      {elyziumStats && <ElyziumStats />}
    </Container>
  )
}

export default PlayerCard
