import styled, { withTheme } from 'styled-components'

// Static
export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100vh;
`

export const VerticalNavigationBarContainer = withTheme(styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme.type === 'light' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.3)'};

  width: 15%;
  padding-left: 15px;
  padding-right: 15px;

  border-right: 0.1px solid rgba(255, 255, 255, 0.2);
`)

export const ContentWrapper = styled.div`
  box-sizing: border-box;

  display: flex;
  width: 85%;
  height: 100%;
`
