import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ACCOUNT_INVITATIONS, useOrganizationQuery } from '../index'
import { useAccount } from 'hooks/context/account-context'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'

const updateInvitation = async (accountId, payload) => {
  const { data } = await apiFetch(paths.accountInvitations(accountId), { method: 'PUT', body: JSON.stringify({ ...payload, decision: 'accept'}) })
  return data
}

export const useAcceptAccountInvitationsQuery = () => {
  const queryClient = useQueryClient()
  const { refetch } = useOrganizationQuery()

  const { refreshCurrentSession } = useUser()
  const { account, setAccount }: any = useAccount()
  const { setOrganization }: any = useOrganization()

  return useMutation(
    (payload) => updateInvitation(account?.id, payload),
    {
      onSuccess: (payload) => {
        queryClient.invalidateQueries([...ACCOUNT_INVITATIONS, account?.id])

        if (!payload?.organization) return setOrganization({})
        setAccount({ ...(account as any), organization: payload?.organization.id })
        setOrganization(payload?.organization)

        return refreshCurrentSession(refetch)
      }
    }
  )
}
