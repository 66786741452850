import React, { useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../lib/constants/colors'
import { OrgDangerZone as DangerZone } from '../../../components/DangerZone'
import { Divider, message, Popover, Form, Button, Alert, Badge } from 'antd'
import CustomInput from 'components/Input'
import { isEmpty, isNil, omitBy } from 'lodash'
import { AddIcon } from '../../../components/VerticalNavBar/styles'
import { useEffect } from 'react'

import Text from 'components/text'
import { useOrganization } from 'hooks/context/organization-context'
import { useDeleteOrganizationQuery, useUpdateOrganizationQuery } from 'hooks/api/organizations'
import CustomButton from 'components/Button'

export const ListItem = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ index }) => index % 2 === 0 ? 'none' : 'rgba(0,0,0,0.1)'};

  &:hover {
    transition: all 1s ease;
    background-color: ${COLORS.SECONDARY_300};
  }
`

const ServerSetupCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    box-shadow: inset 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
  }
`

const ServerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    box-shadow: inset 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
  }
`

const OrganizationTab = () => {
  const [createServerForm] = Form.useForm()
  const [deleteServerForm] = Form.useForm()

  const [newOrgName, setNewOrgName] = useState()
  const [serversWithData, setServersWithData] = useState([])
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [serverPopoverVisible, setServerPopoverVisible] = useState()
  const [error, setError] = useState('All fields are empty')

  const { organization } = useOrganization()
  const { mutateAsync: updateOrganization, isLoading: updateOrganizationLoading } = useUpdateOrganizationQuery()
  const { mutateAsync: deleteOrganization, isLoading: deleteOrganizationLoading } = useDeleteOrganizationQuery()

  const onNewServerSubmit = async (values) => {
    const { serverIp, serverName } = values
    if (!serverIp || !serverName) return

    try {
      const updatedOrg = await updateOrganization({ servers: (organization?.servers || []).concat([{ name: serverName, ip: serverIp }]) })

      if (!updatedOrg) {
        setError('Something went wrong and couldn\'t add the server')
        message.error('Something went wrong and couldn\'t add the server', 5)
        return
      }
      
      setError(null)
      message.success('Successfully added a new server', 5)

      createServerForm.resetFields()
    } catch (e) {
      setError('Something went wrong and couldn\'t add the server')
      message.error('Something went wrong and couldn\'t add the server', 5)
    }
  }
  const onDeleteServerSubmit = async (values) => {
    const { ip, name } = serverPopoverVisible
    if (!ip || !name) return

    try {
      const updatedOrg = await updateOrganization({ servers: (organization?.servers || []).filter(server => server.ip !== ip && server.name !== name) })

      if (!updatedOrg) {
        setError('Something went wrong and couldn\'t delete the server')
        message.error('Something went wrong and couldn\'t delete the server', 5)
        return
      }
      
      setError(null)
      message.success('Successfully deleted the server', 5)

      createServerForm.resetFields()
    } catch (e) {
      setError('Something went wrong and couldn\'t delete the server')
      message.error(`Something went wrong and couldn\'t delete the server: ${e}`, 5)
    }
  }
  
  const validateResponse = (response) => {
    if (!response.ok) throw Error(response.statusText)
    return response
  }

  const request = async (path, server) => fetch(`${path}${server.ip}`, { headers: new Headers().append('Access-Control-Allow-Origin', '*')  })

  useEffect(async () => {
    if (!organization.servers) return

    const resultingServers = await Promise.all(organization.servers.map(async (server) => {
      const response = await request('https://api.mcsrvstat.us/2/', server)
      const resultObject = await response.text()

      return omitBy({ name: server.name, ip: server.ip, metadata: JSON.parse(resultObject)}, isNil)
    }))

    if (isEmpty(serversWithData)) setServersWithData(organization?.servers)
    setServersWithData(resultingServers)
  }, [organization])

  return (<div>
    <Text type='h3'>Organization</Text>
    <Text type='label'>Manage information regards your organization here</Text>
    
    <Divider style={{backgroundColor: 'white', margin: '20px 0'}} />
    <div style={{width: '100%', display: 'flex', margin: '30px 0', alignItems: 'center'}}>
      <Text type='label' style={{width: '200px', marginRight: '5%'}}>Organization Name</Text>
      <CustomInput disabled={updateOrganizationLoading} style={{width: '300px', height: '50px'}} placeholder={organization?.name || 'Your organization\'s name'} onChange={(e) => setNewOrgName(e.target.value)} onPressEnter={async () => {
        if (!newOrgName) return
        const organization = await updateOrganization({ name: newOrgName })

        if (organization) message.success('Successfully changed your display name')
        else message.error('Failed to update your display name')
        setNewOrgName()
      }} />
    </div>

    <Divider style={{backgroundColor: 'white', margin: '20px 0'}} />
    <div style={{width: '100%', display: 'flex', margin: '30px 0', alignItems: 'center'}}>
      <Text type='label' style={{width: '200px', marginRight: '5%'}}>Registered Servers</Text>
      {serversWithData?.length < 5 && <Popover
        content={<Form form={createServerForm} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} name='newServerForm' onFinish={onNewServerSubmit}>
          {(error && error !== 'All fields are empty') && <Alert message={error} type='error' showIcon closable />}

          <Form.Item name='serverIp' rules={[{ required: true, message: 'Please provide the server\'s IP address or domain!' }, () => ({
            validator(_, value) {
              if (!value || /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(?::\d{0,4})?\b$/.test(value) || /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/.test(value)) {
                setError(null)
                return Promise.resolve();
              }
              setError('The IP address provided is invalid!')
              return Promise.reject();
            },
          })]}>
            <CustomInput style={{width: '300px', height: '10px'}} placeholder="The server's IP address" />
          </Form.Item>
          <Form.Item name='serverName' rules={[{ required: true, message: 'Please provide the server\'s name' }]}>
            <CustomInput style={{width: '300px', height: '10px'}} placeholder="The server's name" />
          </Form.Item>
          <Form.Item>
            <CustomButton title='Add Server' htmlType='submit' type='primary' disabled={error} />
          </Form.Item>
        </Form>}
        title='Register your Minecraft server'
        trigger='click'
        visible={popoverVisible}
        onVisibleChange={() => setPopoverVisible(!popoverVisible)}
      >
        <ServerSetupCard><AddIcon style={{fontSize: '3rem'}} /></ServerSetupCard>
      </Popover>}

      <div style={{display: 'flex', justifyContent: 'space-around'}}>
        {serversWithData?.map(server => <Popover
          content={<Form form={deleteServerForm} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} name='deleteServerForm' onFinish={onDeleteServerSubmit}>
            <Form.Item>
              <Button htmlType='submit' style={{ marginTop: '20px', backgroundColor: COLORS.SECONDARY_300, border: 'none' }} type='primary'>Yes</Button>
            </Form.Item>
          </Form>}
          title='Are you sure you want to delete the server?'
          trigger='click'
          visible={serverPopoverVisible?.ip === server.ip && serverPopoverVisible?.name === server.name && !popoverVisible}
          onVisibleChange={() => (serverPopoverVisible?.ip === server.ip && serverPopoverVisible?.name === server.name) ? setServerPopoverVisible(null) : setServerPopoverVisible({ ip: server.ip, name: server.name })}
        >
          <ServerCard>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <h3 style={{fontWeight: 'bold', margin: 0, padding: 0, marginRight: '10px'}}>{server.name}</h3>
              <Badge status={server?.metadata?.online ? 'success' : 'error'} style={{margin: 0, padding: 0}} />
            </div>
            <h3 style={{fontWeight: 'bold', margin: 0, padding: 0}}>{server?.metadata?.online && `(${server?.metadata?.players?.online}/${server?.metadata?.players?.max})`}</h3>
            <p style={{margin: 0, padding: 0}}>{server.ip}</p>
          </ServerCard>
        </Popover>)}
      </div>
    </div>

    <Divider style={{backgroundColor: 'white', margin: '20px 0'}} />

    {/* <DangerZone width='70%' onClick={async () => {
      const { id: organization } = await deleteOrganization()
      if (!organization) return message.error('Could not delete your organization')
      message.success('Successfully deleted your organization')
    }} /> */}
  </div>)
}

export default OrganizationTab
