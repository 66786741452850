import { useLocation } from 'react-router-dom'

// Static files
import LoginFrame from 'static/DarkLoginFrame.png'
import Crystal from 'static/Crystal.png'
import RegisterFrame from 'static/DarkRegisterFrame.png'

// Specialized components
import { Wrapper, LeftContainer, RightContainer, Media, HeadingText, FrameImage, CrystalImage } from './styles'

const AuthLayout = ({ children }) => {
  const { pathname } = useLocation()

  const isRegisterPage = pathname.split('/').pop() === 'register'

  const Login = (
    <Media>
      <HeadingText type='h1'>Everything<br/> in one place</HeadingText>
      <FrameImage src={LoginFrame} alt='Login Frame' />
      <CrystalImage src={Crystal} alt='Crystal' />
    </Media>
  )

  const Register = (
    <Media>
      <HeadingText type='h1'>Join the new unexplored universe</HeadingText>
      <FrameImage src={RegisterFrame} alt='Registration Frame' />
    </Media>
  )

  return (
    <Wrapper>
      <LeftContainer>
        {isRegisterPage ? Register : Login}
      </LeftContainer>
      <RightContainer>
        {children}
      </RightContainer>
    </Wrapper>
  )
}

export default AuthLayout
