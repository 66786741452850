import Text from 'components/text'
import { useState } from 'react'
import styled, { withTheme } from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const Container = styled.div`
  filter: blur(8px);
  -webkit-filter: blur(8px);
`

const Overlay = styled.div`
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
`

const OverlayText = styled(Text)`
  transition: all 0.5s ease;
  opacity: ${props => props.visible ? 1 : 0};
`

const UnselectableText = styled(Text)`
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`


const Paywall = ({ message, plugin = 'Oblivion', children }) => {
  const [overlayVisible, setOverlayVisible] = useState(false)

  return (
    <Wrapper onMouseEnter={() => setOverlayVisible(true)} onMouseLeave={() => setOverlayVisible(false)}>
      <Container style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', zIndex: 1}}>
        {children ? children : <UnselectableText type='h5'>{`To view this content, you need to own ${plugin}`}</UnselectableText>}
      </Container>
      <Overlay>
        <OverlayText type='h5' className='test' visible={overlayVisible}>{message ? message : `To view this content, you need to own ${plugin}`}</OverlayText>
      </Overlay>
    </Wrapper>
  )
}

export default withTheme(Paywall)
