import styled from 'styled-components'

import { VscHome, VscHubot, VscSettingsGear, VscSignOut, VscAdd, VscPulse } from 'react-icons/vsc'


// Constants
import { COLORS } from '../../lib/constants/colors';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LogoItem = styled.img`
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 15px;
`

export const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  height: 100%;
`

export const AddIcon = styled(VscAdd)`
  width: 100%;
  font-size: 1.4rem;
  padding: 0px;
  color: ${({ active, theme }) => active ? theme.colors.FONT_INVERSE : theme.colors.FONT};
`

export const DashboardIcon = styled(VscHome)`
  width: 20%;
  font-size: 1.5rem;
  padding: 0px;
  margin-right: 10px;
  color: ${({ active, theme }) => active ? theme.colors.FONT_INVERSE : theme.colors.FONT};
`

export const ElyziumIcon = styled(VscHubot)`
  width: 20%;
  font-size: 1.5rem;
  padding: 0px;
  margin-right: 10px;
  color: ${({ active, theme }) => active ? theme.colors.FONT_INVERSE : theme.colors.FONT};
`

export const OblivionIcon = styled(VscPulse)`
  width: 20%;
  font-size: 1.5rem;
  padding: 0px;
  margin-right: 10px;
  color: ${({ active, theme }) => active ? theme.colors.FONT_INVERSE : theme.colors.FONT};
`

export const SettingsIcon = styled(VscSettingsGear)`
  width: 20%;
  font-size: 1.4rem;
  padding: 0px;
  margin-right: 10px;
  color: ${({ active, theme }) => active ? theme.colors.FONT_INVERSE : theme.colors.FONT};
`

export const SignOutIcon = styled(VscSignOut)`
  width: 20%;
  font-size: 1.4rem;
  padding: 0px;
  margin-right: 10px;
  color: ${({ active, theme }) => active ? theme.colors.FONT_INVERSE : theme.colors.FONT};
`

export const WelcomeMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 20px;
  width: 85%;
`
