import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { ORGANIZATION, organizationsPaths as paths } from './index'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'

const deleteOrganization = async (organizationId) => {
  const { data } = await apiFetch(paths.organization(organizationId), { method: 'DELETE' })
  return data
}

export const useDeleteOrganizationQuery = () => {
  const queryClient = useQueryClient()
  const { refreshCurrentSession } = useUser()
  const { organization }: any = useOrganization()

  return useMutation(
    () => deleteOrganization(organization?.id),
    {
      onSuccess: () => {
        refreshCurrentSession()
        queryClient.invalidateQueries([ORGANIZATION, organization?.id])
      }
    }
  )
}
