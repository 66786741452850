import styled, { withTheme } from 'styled-components'
import { Divider, Button } from 'antd'
import Text from 'components/text'

export const CategoryDivider = withTheme(styled(Divider)`
  background-color: ${props => props.theme.colors.FONT};
  margin: 30px 0;
`)

export const Asterix = withTheme(styled.span`
  color: ${props => props.theme.colors.ERROR_300};
`)

export const UpdatePasswordButton = withTheme(styled(Button)`
  margin-top: 20px;
  background-color: ${({ error, theme }) => error ? theme.colors.GHOST : theme.colors.MAIN_100};
  border: none;
`)

export const CategoryContainer = withTheme(styled.div`
  display: flex;

  width: 100%;
  align-items: center;
  margin-bottom: 0;
`)

export const SpecialText = styled(Text)`
  width: 200px;
  margin-right: 5%;
`
