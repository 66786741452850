import { createGlobalStyle } from 'styled-components'
import { COLORS } from './colors'

import DarkDashboardBG from '../static/DarkDashboardBG4K.png'
import LightDashboardBG from '../static/LightDashboardBG.png'

export const GlobalStyles = createGlobalStyle`
  body {
    transition: background 0.2s ease-in, color 0.2s ease-in;
    background: ${({ theme }) => theme.background};
  }
`

export const auroraDark = {
  type: 'dark',
  background: `url(${DarkDashboardBG}) no-repeat center;`,
  colors: {
    MAIN: COLORS.DARK_PRIMARY,
    MAIN_DARK: COLORS.DARK_PRIMARY_DARK,
    MAIN_DARKER: COLORS.DARK_PRIMARY_DARKER,

    FONT: COLORS.WHITE_100,
    FONT_LITE: COLORS.WHITE_400,
    FONT_INVERSE: COLORS.EERIE_BLACK,
    FONT_INVERSE_LITE: COLORS.EERIE_BLACK_LITE,

    BACKGROUND_LIGHTER: COLORS.DARK_BACKGROUND_LIGHTER,
    BACKGROUND_LIGHT: COLORS.DARK_BACKGROUND_LIGHT,
    BACKGROUND: COLORS.DARK_BACKGROUND,
    BACKGROUND_INVERSE: COLORS.WHITE_100,

    DISABLED: COLORS.WHITE_400,

    GHOST: COLORS.WHITE_400,
    GHOST_HOVER: COLORS.WHITE_100,

    CLICKABLE: COLORS.SAPPHIRE_BLUE,
    CLICKABLE_HOVER: COLORS.FLOURESCENT_BLUE,

    SUCCESS_100: COLORS.SUCCESS_100,
    SUCCESS_200: COLORS.SUCCESS_200,
    SUCCESS_300: COLORS.SUCCESS_300,
    SUCCESS_400: COLORS.SUCCESS_400,
    SUCCESS_500: COLORS.SUCCESS_500,
  
    ERROR_100: COLORS.ERROR_100,
    ERROR_200: COLORS.ERROR_200,
    ERROR_300: COLORS.ERROR_300,
    ERROR_400: COLORS.ERROR_400,
    ERROR_500: COLORS.ERROR_500,
  
    WARNING_100: COLORS.WARNING_100,
    WARNING_200: COLORS.WARNING_200,
    WARNING_300: COLORS.WARNING_300,
  
    INFO_100: COLORS.INFO_100,
    INFO_200: COLORS.INFO_200,
    INFO_300: COLORS.INFO_300
  },
  fontFamily: {
    h1: 'Beaufort-Bold',
    h2: 'Beaufort-Bold',
    h3: 'Beaufort-Bold',
    h4: 'Beaufort-Bold',
    h5: 'Beaufort-Bold',
    p: 'Beaufort',
    boldLabel: 'Spiegel-Bold',
    label: 'Spiegel',
    mini: 'Spiegel',
    largeNumber: 'Spiegel-Bold',
    statNumber: 'Beaufort-Bold'
  },
  fontSize: {
    h1: '53px',
    h2: '37px',
    h3: '31px',
    h4: '24px',
    h5: '19px',
    p: '19px',
    boldLabel: '15px',
    label: '15px',
    mini: '10px',
    largeNumber: '76px',
    statNumber: '19px'
  },
  lineHeight: {
    h1: '56px',
    h2: '43px',
    h3: '37px',
    h4: '29px',
    h5: '24px',
    p: '27px',
    boldLabel: '21px',
    label: '21px',
    mini: '13px',
    largeNumber: '83px',
    statNumber: '27px'
  }
}

export const auroraLight = {
  type: 'light',
  background: `url(${LightDashboardBG}) no-repeat center;`,
  colors: {
    MAIN: COLORS.LIGHT_PRIMARY,
    MAIN_DARK: COLORS.LIGHT_PRIMARY_DARK,
    MAIN_DARKER: COLORS.LIGHT_PRIMARY_DARKER,

    FONT: COLORS.EERIE_BLACK,
    FONT_LITE: COLORS.CHARCHOAL,
    FONT_INVERSE: COLORS.WHITE_100,
    FONT_INVERSE_LITE: COLORS.WHITE_400,

    BACKGROUND_LIGHTER: COLORS.WHITE_300,
    BACKGROUND_LIGHT: COLORS.WHITE_200,
    BACKGROUND: COLORS.WHITE_100,
    BACKGROUND_INVERSE: COLORS.DARK_BACKGROUND,
    BACKGROUND_INVERSE_LITE: COLORS.DARK_BACKGROUND_LIGHT,

    DISABLED: COLORS.GRAY_100,

    GHOST: COLORS.GRAY_100,
    GHOST_HOVER: COLORS.GRAY_400,

    CLICKABLE: COLORS.SAPPHIRE_BLUE,
    CLICKABLE_HOVER: COLORS.FLOURESCENT_BLUE,

    SUCCESS_100: COLORS.SUCCESS_100,
    SUCCESS_200: COLORS.SUCCESS_200,
    SUCCESS_300: COLORS.SUCCESS_300,
    SUCCESS_400: COLORS.SUCCESS_400,
    SUCCESS_500: COLORS.SUCCESS_500,
  
    ERROR_100: COLORS.ERROR_100,
    ERROR_200: COLORS.ERROR_200,
    ERROR_300: COLORS.ERROR_300,
    ERROR_400: COLORS.ERROR_400,
    ERROR_500: COLORS.ERROR_500,
  
    WARNING_100: COLORS.WARNING_100,
    WARNING_200: COLORS.WARNING_200,
    WARNING_300: COLORS.WARNING_300,
  
    INFO_100: COLORS.INFO_100,
    INFO_200: COLORS.INFO_200,
    INFO_300: COLORS.INFO_300
  },
  fontFamily: {
    h1: 'Beaufort-Bold',
    h2: 'Beaufort-Bold',
    h3: 'Beaufort-Bold',
    h4: 'Beaufort-Bold',
    h5: 'Beaufort-Bold',
    p: 'Beaufort',
    boldLabel: 'Spiegel-Bold',
    label: 'Spiegel',
    mini: 'Spiegel',
    largeNumber: 'Spiegel-Bold',
    statNumber: 'Beaufort-Bold'
  },
  fontSize: {
    h1: '53px',
    h2: '37px',
    h3: '31px',
    h4: '24px',
    h5: '19px',
    p: '19px',
    boldLabel: '15px',
    label: '15px',
    mini: '13px',
    largeNumber: '76px',
    statNumber: '19px'
  },
  lineHeight: {
    h1: '56px',
    h2: '43px',
    h3: '37px',
    h4: '29px',
    h5: '24px',
    p: '27px',
    boldLabel: '21px',
    label: '21px',
    mini: '15px',
    largeNumber: '83px',
    statNumber: '27px'
  }
}

