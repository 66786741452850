import React, { createContext, useContext, useMemo, useState } from 'react'
import { isUndefined } from 'lodash'

export const CURRENT_CONNECTOR = 'current_connector_context'

export const ConnectorContext = createContext({
  connector: null,
  setConnector: (connector) => { }
})

export const setLocalStorage = (connector) => {
  localStorage.setItem(CURRENT_CONNECTOR, JSON.stringify(connector))
}

export const getLocalStorage = () => {
  const connector = !isUndefined(localStorage.getItem(CURRENT_CONNECTOR)) ? JSON.parse(localStorage.getItem(CURRENT_CONNECTOR)) : {}
  return { connector }
}

export const ConnectorProvider = (props) => {
  const [connector, setConnectorContext] = useState(null)

  const setConnector = (connector) => {
    setLocalStorage(connector)
    setConnectorContext(connector)
  }

  const values = useMemo(() => ({ connector, setConnector }), [connector])

  return (
    <ConnectorContext.Provider value={values}>
      {props.children}
    </ConnectorContext.Provider>
  )
}

export const useConnector = () => {
  const context = useContext(ConnectorContext)

  if (context === undefined) throw new Error('`useConnector` must be within a `ConnectorProvider`')

  return context
}
