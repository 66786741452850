import React, { useState } from 'react'
import styled, { useTheme, withTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Steps, Row, Col, Button, Table, Select, Checkbox, Space, message } from 'antd'
import { isNil, isUndefined, omitBy } from 'lodash'
import CustomInput from '../../../../components/Input'
import { COLORS } from '../../../../lib/constants/colors'
import Text from 'components/text'
import { useOrganization } from 'hooks/context/organization-context'
import { useSendInvitationQuery } from 'hooks/api/organizations'
import { usePurchases } from 'hooks/context/purchases-context'
import CustomButton from 'components/Button'

import { ListItem, CustomModal, ButtonsContainer } from './styles'

const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email)

const parsePermissions = (permissions) => Object.keys(permissions).map(key => ({ product: key, role: permissions[key]})).filter(item => item.product !== 'org')

const InvitePlayerModal = ({ children, handleOk, handleCancel, visible }) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const { organization: currentOrganization } = useOrganization()
  const { purchases } = usePurchases()
  const ownedProducts = purchases.map(purchase => purchase.product) || []

  const { mutateAsync: sendInvitation, isLoading: sendInvitationLoading } = useSendInvitationQuery()

  const [step, setStep] = useState(0)
  const [email, setEmail] = useState()
  const [permissions, setPermissions] = useState({})

  const reset = () => {
    setStep(0)
    setEmail(undefined)
    setPermissions({})
    handleOk()
  }

  const isMemberAlreadyJoined = () => !isNil(currentOrganization?.accounts?.find(account => account.email === email ))

  const steps = [
    {
      title: 'EMAIL',
      content: () => (
        <>
          <Text type='h3'>Invite a new user by email</Text>
          <p>Enter the email address</p>
            <CustomInput style={{ width: '100%', height: '40px' }} value={email} placeholder='bob@email.com' onChange={(e) => setEmail(e.target.value)} type='email' />
  
          <ButtonsContainer>
            <CustomButton title='Back' btnType='default' onClick={() => setStep(0)} disabled clickable />
            <CustomButton title='Next' disabled={!email || !isEmailValid(email) || isMemberAlreadyJoined()} onClick={() => setStep(1)} clickable/>
          </ButtonsContainer>
        </>
      )
    },
    {
      title: 'PERMISSIONS',
      content: () => <>
        <Text type='h5' capitalized={false}>Invited account: <span style={{ fontWeight: 'bold', color: theme.colors.MAIN}}>{email}</span></Text>

        <div id='legend-row' style={{display: 'flex', height: '50px', alignItems: 'center', padding: '5px 20px', backgroundColor: theme.colors.BACKGROUND }}>
          <p style={{width: '10%', margin: 0, padding: 0, fontFamily: 'Montserrat-Bold'}}></p>
          <p style={{width: '30%', margin: 0, padding: 0, fontFamily: 'Montserrat-Bold'}}>Product</p>
          <p style={{width: '30%', margin: 0, padding: 0, fontFamily: 'Montserrat-Bold'}}>Access Permissions</p>
        </div>
        <ListItem key='organization'>
            <p style={{width: '10%', margin: 0, padding: 0}}>
              <Checkbox key='organization' onChange={(e) => {
                  const isChecked = e.target.checked

                  const newPermissions = { ...permissions }
                  newPermissions.org = isChecked ? 'viewer' : undefined

                  setPermissions(omitBy(newPermissions, isNil))
              }} />
            </p>
            <p style={{width: '30%', margin: 0, padding: 0}}>General Organization</p>
            <p style={{width: '30%', margin: 0, padding: 0}}>        
              <Select disabled={isUndefined(permissions?.org)} onChange={(e) => {
                const newPermissions = { ...permissions }
                newPermissions.org = e
                setPermissions(omitBy(newPermissions, isNil))
              }} style={{ color: theme.colors.FONT, borderRadius: '15px', boxShadow: '0px 3px 15px 0px rgba(0, 0, 0, 0.5)', width: '150px'}} bordered={false} defaultValue='viewer'>
                <Select.Option value='viewer'>View Only</Select.Option>
                <Select.Option value='user'>User</Select.Option>
                <Select.Option value='admin'>Admin</Select.Option>
              </Select>
            </p>
          </ListItem>
        {ownedProducts.map(product => {
          return <ListItem key={product}>
            <p style={{width: '10%', margin: 0, padding: 0}}>
              <Checkbox key={product} onChange={(e) => {
                  const isChecked = e.target.checked

                  const newPermissions = { ...permissions }
                  newPermissions[product] = isChecked ? 'viewer' : undefined

                  setPermissions(omitBy(newPermissions, isNil))
              }} />
            </p>
            <p style={{width: '30%', margin: 0, padding: 0}}>{product[0].toUpperCase() + product.substring(1)}</p>
            <p style={{width: '30%', margin: 0, padding: 0}}>        
              <Select disabled={isUndefined(permissions?.[product])} onChange={(e) => {
                const newPermissions = { ...permissions }
                newPermissions[product] = e
                setPermissions(omitBy(newPermissions, isNil))
              }} style={{ color: COLORS.DARK_PRIMARY_200, borderRadius: '15px', boxShadow: '0px 3px 15px 0px rgba(0, 0, 0, 0.5)', width: 150}} bordered={false} defaultValue='viewer'>
                <Select.Option value='viewer'>View Only</Select.Option>
                <Select.Option value='user'>User</Select.Option>
                <Select.Option value='admin'>Admin</Select.Option>
              </Select>
            </p>
          </ListItem>
        })}

      <ButtonsContainer>
        <CustomButton title='Back' onClick={() => setStep(0)} btnType='default' clickable />
        <CustomButton title='Next' onClick={() => setStep(2)} clickable />
      </ButtonsContainer></>,
    },
    {
      title: 'INVITE',
      content: () => <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Text type='h5' style={{color: theme.colors.MAIN}}>{email}</Text>
        <p style={{fontWeight: 'bold'}}>Invite your teammate to {currentOrganization.name}</p>
        <p>Send them a friendly invite with sign in instructions</p>
        <CustomButton title='Invite' disabled={sendInvitationLoading} style={{ marginTop: '20px' }} onClick={async () => {
          reset()

          const response = await sendInvitation({ email, permissions: parsePermissions(permissions), role: permissions?.org })
          if (response?.token) message.success(`You invited ${email} to join your organization!`, 10)
          else message.error(`Something went wrong when inviting ${email} to join your organization. Are you sure this email belongs to an existing user?`)
        }} />

        <ButtonsContainer>
          <CustomButton title='Back' btnType='default' onClick={() => setStep(1)} clickable />
        </ButtonsContainer>
      </div>,
    },
  ]

  return (<CustomModal title='Invite a new user' footer={null} width={800} visible={visible} onOk={handleOk} onCancel={() => {
      setStep(0)
      setEmail(null)
      setPermissions({})
      handleCancel()
    }} bodyStyle={{ overflow: 'hidden', background: theme.colors.BACKGROUND_LIGHTER, color: theme.colors.FONT }}>
      <Row style={{ justifyContent: 'center', padding: '1%' }}>
        <Col span={24}>
          <Steps current={step} type='navigation' style={{marginBottom: '5%'}}>
            {steps.map(item => (
              <Steps.Step key={item.title} title={<Text type='p'>{item.title}</Text>} style={{ color: theme.colors.FONT }} />
            ))}
          </Steps>
            

          {steps[step].content()}
        </Col>
      </Row>
  </CustomModal>)
}

export default InvitePlayerModal
