export const PURCHASE = 'purchase'

export * from './get-purchases'

export const purchasePaths = {
  filter: (path, query) => `${path}?${query}`,

  account: (identity) => `/accounts/${identity}`,
  base: (account) => `/accounts/${account}/purchases`,

  purchases: (account) => `${purchasePaths.base(account)}/purchases`,
  purchase: (account, identity) => `${purchasePaths.purchases(account)}/${identity}/`
}
