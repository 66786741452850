import React from 'react'
import { isEmpty } from 'lodash'
import styled, { withTheme } from 'styled-components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom'

// Specialized components
import { Wrapper, LogoItem, NavItemsContainer, WelcomeMessageContainer, SignOutIcon, DashboardIcon, AddIcon, ElyziumIcon, SettingsIcon, OblivionIcon } from './styles'

// Static
import Logo from '../../static/Logo.png'
import Text from 'components/text'
import { useUser } from 'hooks/context/user-context'
import { useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { useAccount } from 'hooks/context/account-context'

export const NavItem = withTheme(styled.div`
  margin: 0;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 15px;
  margin-bottom: 10px;
  width: 100%;

  background-color: ${props => props.active ? props.theme.colors.MAIN_DARKER : 'none'};

  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.MAIN};
  }

  &:hover > * {
    color: ${props => props.theme.colors.FONT_INVERSE};
  }

  & > * {
    color: ${({ active, theme }) => active ? theme.colors.FONT_INVERSE : theme.colors.FONT};
  }
`)

const NavigationBar = ({ onChange, prefix, placeholder }) => {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const history = useHistory()
  
  const { logout } = useUser()
  const { organization } = useOrganization()
  const { account } = useAccount()

  const hasPermissionForPlayerbasePage = organization?.memberships?.find(membership => membership.account === account?.id)?.permissions?.length > 0
  const hasPermissionForElyziumPage = !!organization?.memberships?.find(membership => membership.account === account?.id)?.permissions?.find(permission => permission.product === 'elyzium')
  const hasPermissionForOblivionPage = !!organization?.memberships?.find(membership => membership.account === account?.id)?.permissions?.find(permission => permission.product === 'oblivion')

  const navItems = [{
    name: 'Dashboard',
    condition: true,
    path: '/',
    icon: <NavItem active={pathname.split('/').pop() === ''} onClick={() => history.push('/')}><DashboardIcon active={pathname.split('/').pop() === ''} /><Text>Dashboard</Text></NavItem>
  }, {
    name: 'Playerbase',
    condition: hasPermissionForPlayerbasePage,
    path: '/playerbase',
    icon: <NavItem active={pathname.split('/').pop() === 'playerbase'} onClick={() => history.push('/playerbase')}><ElyziumIcon active={pathname.split('/').pop() === 'playerbase'} /><Text>Playerbase</Text></NavItem>
  }, {
    name: 'Elyzium',
    condition: hasPermissionForElyziumPage,
    path: '/elyzium',
    icon: <NavItem active={pathname.split('/').pop() === 'elyzium'} onClick={() => history.push('/elyzium')}><ElyziumIcon active={pathname.split('/').pop() === 'elyzium'} /><Text>Elyzium</Text></NavItem>
  }, {
    name: 'Oblivion',
    condition: hasPermissionForOblivionPage,
    path: '/oblivion',
    icon: <NavItem active={pathname.split('/').pop() === 'oblivion'} onClick={() => history.push('/oblivion')}><OblivionIcon active={pathname.split('/').pop() === 'oblivion'}/><Text>Oblivion</Text></NavItem>
  }, {
    name: 'Add another plugin',
    condition: true,
    path: '/plugins',
    icon: <NavItem active={pathname.split('/').pop() === 'plugins'} onClick={() => history.push('/plugins')}><AddIcon active={pathname.split('/').pop() === 'plugins'}/></NavItem>
  }, {
    name: 'Settings',
    condition: true,
    path: '/settings',
    icon: <NavItem active={pathname.split('/').pop() === 'settings'} onClick={() => history.push('/settings')} style={{marginTop: 'auto', justifySelf: 'flex-end'}}><SettingsIcon active={pathname.split('/').pop() === 'settings'}/><Text>Settings</Text></NavItem>
  }, {
    name: 'Log Out',
    condition: true,
    path: '/',
    icon: <NavItem onClick={() => { logout(); queryClient.clear(); } } style={{justifySelf: 'flex-end'}}><SignOutIcon /><Text>Log Out</Text></NavItem>
  }]

  return (
    <Wrapper>
      <WelcomeMessageContainer>
        <LogoItem src={Logo} alt='Frame' style={{ display: 'flex', width: '50px', paddingBottom: '5px' }} />
        <Text type='h5'>Hi, {account?.name || 'Steve'}</Text>
      </WelcomeMessageContainer>
      <NavItemsContainer>
        {navItems.map(item => item.condition && item.icon)}
      </NavItemsContainer>
    </Wrapper>
  )
}

export default NavigationBar
