import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { connectorsPaths as paths, CUSTOM_QUERY } from '../index'
import { useOrganization } from 'hooks/context/organization-context'
import { useConnector } from 'hooks/context/connector-context'

const getUserData = async (organizationId, elyziumId, playerUuid) => {
  const { data } = await apiFetch(paths.query(organizationId, elyziumId), { method: 'POST', body: JSON.stringify({ query: `SELECT * FROM elyzium_user_data WHERE uuid = "${playerUuid}"` })})
  return data?.[0]
}

const getAllPunishmentsByUuid = async (organizationId, connectorId, playerUuid) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT punishedUserData.name AS punishedName, punisherUserData.name AS punisherName, revokedByUserData.name AS revokedByName, \`elyzium_punishments\`.* FROM \`elyzium_punishments\` LEFT JOIN \`elyzium_user_data\` AS punishedUserData ON punishedUserData.uuid = \`elyzium_punishments\`.punishedUuid LEFT JOIN \`elyzium_user_data\` AS punisherUserData ON punisherUserData.uuid = \`elyzium_punishments\`.punisherUuid LEFT JOIN \`elyzium_user_data\` AS revokedByUserData ON revokedByUserData.uuid = \`elyzium_punishments\`.revokedByUuid WHERE punishedUuid = "${playerUuid}"` })})
  return data || []
}

const getAllWarnings = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT * FROM elyzium_warnings` })})
  return data || []
}

const getAllWarningsByUuid = async (organizationId, connectorId, playerUuid) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT byUserData.name AS warnedByName, toUserData.name AS warnedName, revokedByUserData.name AS revokedByName, \`elyzium_warnings\`.* FROM \`elyzium_warnings\` LEFT JOIN \`elyzium_user_data\` AS byUserData ON byUserData.uuid = \`elyzium_warnings\`.warnedByUuid LEFT JOIN \`elyzium_user_data\` AS toUserData ON toUserData.uuid = \`elyzium_warnings\`.warnedUuid LEFT JOIN \`elyzium_user_data\` AS revokedByUserData ON revokedByUserData.uuid = \`elyzium_warnings\`.revokedByUuid WHERE warnedUuid = "${playerUuid}"` })})
  return data || []
}

const getAllPunishments = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT * FROM elyzium_punishments` })})
  return data || []
}

const getAllUserData = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT * FROM elyzium_user_data` })})
  return data || []
}

const getAllTicketReplies = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT createdByUserData.name AS createdByName, \`elyzium_tickets_replies\`.* FROM \`elyzium_tickets_replies\` LEFT JOIN \`elyzium_user_data\` AS createdByUserData ON createdByUserData.uuid = \`elyzium_tickets_replies\`.createdByUuid` })})
  return data || []
}

const getAllTickets = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT createdByUserData.name AS createdByName, workedOnByUserData.name AS workedOnByName, reportedPlayerUserData.name AS reportedPlayerName, \`elyzium_tickets\`.* FROM \`elyzium_tickets\` LEFT JOIN \`elyzium_user_data\` AS createdByUserData ON createdByUserData.uuid = \`elyzium_tickets\`.createdByUuid LEFT JOIN \`elyzium_user_data\` AS workedOnByUserData ON workedOnByUserData.uuid = \`elyzium_tickets\`.workedOnByUuid LEFT JOIN \`elyzium_user_data\` AS reportedPlayerUserData ON reportedPlayerUserData.uuid = \`elyzium_tickets\`.reportedPlayerUuid` })})
  return data || []
}

const getAllTicketRepliesById = async (organizationId, connectorId, ticketId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT createdByUserData.name AS createdByName, \`elyzium_tickets_replies\`.* FROM \`elyzium_tickets_replies\` LEFT JOIN \`elyzium_user_data\` AS createdByUserData ON createdByUserData.uuid = \`elyzium_tickets_replies\`.createdByUuid WHERE ticketId = "${ticketId}" ORDER BY \`createdAt\`` })})
  return data || []
}

const bannedPlayersInLast24h = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT id FROM elyzium_punishments WHERE createdAt > ${dayjs().subtract(24, 'hour').valueOf()} AND type = 'BAN'` })})
  return data || []
}

const mutedPlayersInLast24h = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT id FROM elyzium_punishments WHERE createdAt > ${dayjs().subtract(24, 'hour').valueOf()} AND type = 'MUTE'` })})
  return data || []
}

const uniqueLoginsLast24h = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT uuid FROM elyzium_user_data WHERE lastLogin > ${dayjs().subtract(24, 'hour').valueOf()}` })})
  return data || []
}

const newTicketsOpenedInLast24h = async (organizationId, connectorId) => {
  const { data } = await apiFetch(paths.query(organizationId, connectorId), { method: 'POST', body: JSON.stringify({ query: `SELECT id FROM elyzium_tickets WHERE createdAt > ${dayjs().subtract(24, 'hour').valueOf()}` })})
  return data || []
}

export const useElyziumUserDataQuery = (playerUuid) => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, playerUuid, 'getUserData'],
    () => getUserData(organization?.id, connector?.id, playerUuid),
    {
      cacheTime: 30 * 60 * 1000,
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
} 

export const useElyziumPunishmentsByUuidQuery = (playerUuid) => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, playerUuid, 'getAllPunishmentsByUuid'],
    () => getAllPunishmentsByUuid(organization?.id, connector?.id, playerUuid),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id && !!playerUuid,
      refetchOnWindowFocus: false
    }
  )
} 

export const useElyziumWarningsQuery = () => {
  const { connector }: any = useConnector()
  const { organization }: any = useOrganization()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'getAllWarnings'],
    () => getAllWarnings(organization?.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
} 

export const useElyziumWarningsByUuidQuery = (playerUuid) => {
  const { connector }: any = useConnector()
  const { organization }: any = useOrganization()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, playerUuid, 'getAllWarningsByUuid'],
    () => getAllWarningsByUuid(organization?.id, connector?.id, playerUuid),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id && !!playerUuid,
      refetchOnWindowFocus: false
    }
  )
} 

export const useElyziumPunishmentsQuery = () => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'getAllPunishments'],
    () => getAllPunishments(organization?.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
} 

export const useElyziumAllUserDataQuery = () => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'getAllUserData'],
    () => getAllUserData(organization?.id, connector?.id),
    {
      cacheTime: 30 * 60 * 1000,
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
} 

export const useElyziumTicketRepliesQuery = () => {
  const { connector }: any = useConnector()
  const { organization }: any = useOrganization()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'getAllTicketReplies'],
    () => getAllTicketReplies(organization?.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
} 

export const useElyziumTicketsQuery = () => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'getAllTickets'],
    () => getAllTickets(organization?.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
}

export const useElyziumTicketRepliesByIdQuery = (ticketId) => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, ticketId, 'getAllTicketRepliesById'],
    () => getAllTicketRepliesById(organization?.id, connector?.id, ticketId),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id && !!ticketId,
      refetchOnWindowFocus: false
    }
  )
}

export const useElyziumBannedPlayersInLast24hQuery = () => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'bannedPlayersInLast24h'],
    () => bannedPlayersInLast24h(organization?.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
}

export const useElyziumMutedlayersInLast24hQuery = () => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'mutedPlayersInLast24h'],
    () => mutedPlayersInLast24h(organization.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
}

export const useElyziumUniqueLoginsLast24hQuery = () => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()

  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'uniqueLoginsLast24h'],
    () => uniqueLoginsLast24h(organization.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
}

export const useElyziumNewTicketsOpenedLast24hQuery = () => {
  const { organization }: any = useOrganization()
  const { connector }: any = useConnector()


  return useQuery(
    [CUSTOM_QUERY, 'elyzium', organization?.id, connector?.id, 'newTicketsOpenedInLast24h'],
    () => newTicketsOpenedInLast24h(organization.id, connector?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!connector?.id,
      refetchOnWindowFocus: false
    }
  )
}
