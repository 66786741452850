import { chunk } from 'lodash'
import React, { useState } from 'react'
import styled, { withTheme, useTheme } from 'styled-components'
import { Popover, Alert} from 'antd'
import { AddIcon } from '../../../components/VerticalNavBar/styles'
import InvitePlayerModal from './InvitePlayerModal'
import { VscCircleSlash, VscInfo } from 'react-icons/vsc'
import PermissionsPopover from '../../../components/PermissionsPopover'
import { useOrganization } from 'hooks/context/organization-context'
import { useRevokeMembershipQuery } from 'hooks/api/organizations'

export const ListItem = withTheme(styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ index }) => index % 2 === 0 ? 'none' : 'rgba(0,0,0,0.1)'};

  &:hover {
    transition: all 0.5s ease;
    background-color: ${props => props.theme.colors.MAIN};
    color: ${props => props.theme.colors.FONT_INVERSE};
  }
`)

export const RevokeMembershipIcon = withTheme(styled(VscCircleSlash)`
  width: 20%;
  font-size: 1.5rem;
  padding: 0px;
  margin-right: 10px;
  color: ${props => props.theme.colors.ERROR_300};

  &:hover {
    cursor: pointer;
  }
`)

export const InfoIcon = styled(VscInfo)`
  width: 20%;
  font-size: 1rem;
  padding: 0;
  margin-right: 0;

  &:hover {
    cursor: pointer;
  }
`

const TeamTab = ({ }) => {
  const theme = useTheme()

  const { mutateAsync: revokeMembership, isLoading: revokeMembershipLoading } = useRevokeMembershipQuery()

  const { organization: currentOrganization } = useOrganization()
  const accounts = currentOrganization?.accounts
  const memberships = currentOrganization?.memberships

  const [currentPage, setCurrentPage] = useState(1)
  const [showInvitePlayerModal, setShowInvitePlayerModal] = useState(false)
  const [showRevokeMembershipPopup, setShowRevokeMembershipPopup] = useState()

  const paginate = (items, perPage, currentPage) => chunk(items, perPage)[currentPage - 1]
  const maxPages = (key, perPage) => chunk(accounts, perPage)?.length || 1

  return (<div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
    <Alert style={{margin: '20px 30px'}} message='In order to invite new teammates, they must have already signed to the web application' type='info' showIcon />
    <InvitePlayerModal handleOk={() => setShowInvitePlayerModal(false)} handleCancel={() => setShowInvitePlayerModal(false)} visible={showInvitePlayerModal} />

    <div id='legend-row' style={{display: 'flex', height: '50px', alignItems: 'center', padding: '5px 20px', backgroundColor: 'rgba(0,0,0,0.1)'}}>
      <p style={{width: '25%', margin: 0, padding: 0}}>Email</p>
      <p style={{width: '20%', margin: 0, padding: 0}}>Display Name</p>
      <div style={{display: 'flex', margin: 0, padding: 0, width: '15%', alignItems: 'center'}}><p style={{ margin: 0, padding: 0, marginRight: '10px'}}>Role</p><PermissionsPopover placement='bottom'><InfoIcon /></PermissionsPopover></div>
      <p style={{width: '20%', margin: 0, padding: 0}}>Join date</p>
      <p style={{width: '20%', margin: 0, padding: 0, display: 'flex', justifyContent: 'flex-end'}}>Actions</p>
    </div>
    {paginate(accounts, 10, 1)?.map((account, key) => {
      return <ListItem key={key} index={key}>
        <p style={{width: '25%', margin: 0, padding: 0}}>{account.email}</p>
        <p style={{width: '20%', margin: 0, padding: 0}}>{account.name}</p>
        <p style={{width: '15%', margin: 0, padding: 0}}>{currentOrganization?.memberships?.find(membership => membership.account === account?.id)?.role}</p>
        <p style={{width: '20%', margin: 0, padding: 0}}>{account.createdAt}</p>
        <div style={{width: '20%', margin: 0, padding: 0, display: 'flex', justifyContent: 'flex-end'}}>
          <Popover
            content={<><a style={{marginRight: '20px'}} onClick={async () => {
              setShowRevokeMembershipPopup(null); 
              await revokeMembership(account?.id)
            }}>Revoke Membership</a><a onClick={() => setShowRevokeMembershipPopup(null)}>Close</a></>}
            title="Do you really want to revoke this member's membership?"
            trigger='click'
            visible={showRevokeMembershipPopup}
          >
            {memberships?.find(membership => membership.account === account.id)?.role !== 'owner' && <RevokeMembershipIcon />}
          </Popover>
        </div>
      </ListItem>
    })}
    <ListItem><AddIcon style={{color: theme.colors.FONT }} onClick={() => setShowInvitePlayerModal(true)} /></ListItem>
  </div>)
}

export default TeamTab
