import { useState } from 'react'
import { Drawer, Avatar, Spin } from 'antd'
import { useTheme } from 'styled-components'

// Components
import Text from 'components/text'
import CustomButton from 'components/Button'
import CustomInput from 'components/Input'

// Hooks
import { useCreateVerificationQuery, useVerificationsQuery } from 'hooks/api/verifications'
import { usePurchases } from 'hooks/context/purchases-context'

// Styled components
import { UltraWrapper, CustomSegment, StepsWrapper, StepsContainer, VerificationCardContainer } from './styles'

const checkVerificationError = (verifications, product) => {
  const verification = verifications?.find(verification => verification.product === product)
  return !!verification?.error
}

const Plugins = () => {
  const theme = useTheme()
  const { purchases = [] } = usePurchases()

  const [stepsDrawerOpen, setStepsDrawerOpen] = useState()
  const [verifyType, setVerifyType] = useState('spigot')

  const [spigotId, setSpigotId] = useState()

  const { data: verifications, isLoading: isLoadingVerifications } = useVerificationsQuery()

  const { mutateAsync: createVerification } = useCreateVerificationQuery()

  const resetVerification = () => {
    setStepsDrawerOpen(undefined)
    setSpigotId(undefined)
  }

  const ownedProducts = purchases?.map((purchase: any) => purchase?.product)

  const getSteps = () => {
    switch (verifyType) {
      default:
      case 'spigot': return (
        <StepsContainer>
          <Text type='h1'>Spigot Verification Steps</Text>
          <Text type='label' style={{marginTop: '10px'}}>1) Enter your Spigot ID</Text>
          <Text type='mini'>(You can find your Spigot ID from your profile page URL)</Text>
          <Text type='mini'>(For example: https://www.spigotmc.org/members/imeduza.665002/ - my ID would be 665002)</Text>
          <CustomInput placeholder='Spigot ID' onChange={(e) => setSpigotId(e.target.value)} />

          <CustomButton btnType='primary' title='Verify' style={{width: '40%', marginTop: '30px'}} disabled={!spigotId}  onClick={async () => {
            await createVerification({ source: verifyType, product: stepsDrawerOpen, sourceId: spigotId } as any)
            resetVerification()
          }} />
        </StepsContainer>
      )
      case 'polymart': return (<div>
        <Text type='h2'>Polymart Verification Steps</Text>
      </div>)
    }
  }

  return (
    <UltraWrapper>
      <Drawer placement='right' closable={false} onClose={() => setStepsDrawerOpen(undefined)} open={stepsDrawerOpen} drawerStyle={{boxSizing: 'border-box', background: theme.colors.BACKGROUND_LIGHTER, color: theme.colors.FONT, overscrollBehaviorY: 'none', height: '100%'}} width='40%'>
        <CustomSegment
          onChange={e => setVerifyType(e)}
          block
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <Avatar style={{ backgroundColor: '#f56a00' }}>S</Avatar>
                  <div>Spigot</div>
                </div>
              ),
              value: 'spigot'
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <Avatar style={{ backgroundColor: '#f56a00' }}>P</Avatar>
                  {/* <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} /> */}
                  <div>Polymart</div>
                </div>
              ),
              value: 'polymart'
            }
          ]}
        />
        <StepsWrapper>{getSteps()}</StepsWrapper>
      </Drawer>
      <VerificationCardContainer>
        <Text type='h3' style={{ textAlign: 'center'}}>Elyzium</Text>
        <Text type='label' style={{ textAlign: 'center'}}>Elyzium is an administration plugin, that offers all of the most advanced tools for server administration, management, and its automation.</Text>
        <div style={{ display: 'flex', marginTop: 'auto', justifyContent: 'space-evenly' }}>
          {isLoadingVerifications ? <Spin /> :
          checkVerificationError(verifications, 'elyzium') ? <CustomButton btnType='primary' title={`${verifications?.find(ver => ver.product === 'elyzium')?.error} (Request manual review on Discord)`} style={{width: '80%'}} onClick={() => window.open('https://discord.gg/js3rYHd9mw', '_blank')} clickable /> :
          !ownedProducts?.includes('elyzium') ? 
            <>
              <CustomButton btnType='default' title='Verify' style={{width: '40%'}} onClick={() => setStepsDrawerOpen('elyzium' as any)} />
              <CustomButton btnType='primary' title='Buy' style={{width: '40%'}} onClick={() => {}} />
            </> : <CustomButton btnType='primary' title='Already own' style={{width: '80%'}} clickable={false} />
          }
        </div>
      </VerificationCardContainer>
    </UltraWrapper>
  )
}

export default Plugins
