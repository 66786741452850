import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { verificationPaths as paths, VERIFICATION } from './index'
import { useAccount } from 'hooks/context/account-context'

const getVerifications = async (accountId) => {
  const { data } = await apiFetch(paths.verifications(accountId))
  return data || []
}

export const useVerificationsQuery = () => {
  const { account }: any = useAccount()

  return useQuery(
    [VERIFICATION, account?.id],
    () => getVerifications(account?.id),
    {
      staleTime: Infinity,
      enabled: !!account?.id,
      refetchOnWindowFocus: false
    }
  )
}
