import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { connectorsPaths as paths, CONNECTOR } from './index'
import { useOrganization } from 'hooks/context/organization-context'
import { useConnector } from 'hooks/context/connector-context'

const postConnector = async (organization, payload) => {
  const { data } = await apiFetch(paths.connectors(organization), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

export const useCreateConnectorQuery = () => {
  const queryClient = useQueryClient()
  const { organization }: any = useOrganization()
  const { setConnector }: any = useConnector()

  return useMutation(
    (payload) => postConnector(organization?.id, payload),
    {
      onSuccess: (connector) => {
        queryClient.invalidateQueries([CONNECTOR, organization?.id])

        if (!connector) return setConnector({})
        return setConnector(connector)
      }
    }
  )
}
