import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import './styles.css'
import dayjs from 'dayjs'
import { Tabs, List, Skeleton, Divider, Empty, Spin, Tag, Popover, Alert } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { orderBy } from 'lodash'

import { VscKebabVertical, VscComment, VscFoldUp } from 'react-icons/vsc'
import { useElyziumTicketRepliesQuery, useElyziumTicketsQuery } from 'hooks/api/connectors'
import Text from 'components/text'
import Comments from './comment-list'
import { Badge } from '@geist-ui/core'
import { useConnector } from 'hooks/context/connector-context'

export const UltraWrapper = styled.div`
  box-sizing: border-box;

  display: flex;
  gap: 15px;
  overscroll-behavior-y: none;
  padding: 0;
  margin: 12px;
  padding: 20px 30px;

  width: 100%;
`

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 5%;
`


export const EditTicket = styled(VscKebabVertical)`
  font-size: 1rem;
  padding: 0;
  margin: 0;
  color: ${props => props.theme.colors.FONT};

  &:hover {
    cursor: pointer;
  }
`

export const Comment = styled(VscComment)`
  font-size: 1rem;
  padding: 0px;
  color: ${props => props.theme.colors.FONT};
`

export const Priority = styled(VscFoldUp)`
  font-size: 1rem;
  padding: 0px;
  color: ${props => {
    if (props.priority < 3) return props.theme.colors.SUCCESS_300
    else if (props.priority === 3) return props.theme.colors.WARNING_300
    else return props.theme.colors.ERROR_300
  }};
`

export const CustomTabs = styled(Tabs)`
  color: ${({ theme }) => theme.colors.FONT};
  width: 100%;
  height: 100%;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.MAIN};
    font-weight: 500;
  }
  
  .ant-tabs-ink-bar {
    /* position: absolute; */
    background: ${({ theme }) => theme.colors.MAIN};
    /* pointer-events: none; */
  }
  
  .ant-tabs-tab:hover {
    color: ${({ theme }) => theme.colors.MAIN};
  }
`

const ListItem = styled(List.Item)`
  &::before {
    display: none;
  }
`

const CommentsTag = styled.div`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  padding: 5px;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.BACKGROUND_LIGHT};

    border-radius: 5px;
  }

  ${props => props.active && `background: ${props.theme.colors.BACKGROUND_LIGHT}; border-radius: 5px;`}
`

const TicketList = ({ filter, tickets: allTickets, setComments, comments, ticketReplies }) => {
  const theme = useTheme()

  const getTicketsWithFilter = (filter) => {
    switch (filter) {
    default:
    case 'All': return allTickets
    case 'Open': return allTickets?.filter(ticket => ['NEW', 'OPEN'].includes(ticket?.status))
    case 'Work in progress': return allTickets?.filter(ticket => ['WIP'].includes(ticket?.status))
    case 'Archived': return allTickets?.filter(ticket => ['CLOSED', 'RESOLVED'].includes(ticket?.status))
    }
  }

  const tickets = getTicketsWithFilter(filter)

  return (
    <InfiniteScroll
      dataLength={tickets?.length || 0}
      next={() => [...Array(10).keys()]}
      hasMore={false ?? tickets?.length < 50}
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      endMessage={tickets?.length > 0 && <Divider plain style={{ color: theme.colors.FONT }}>No more tickets to display 🤐</Divider>}
      scrollableTarget='scrollableDiv'
    >
      <List
        style={{ padding: '0 10px' }}
        grid={{ gutter: 16, column: 2 }}
        locale={{ emptyText: <Empty description='No tickets found' style={{ color: theme.colors.FONT }} /> }}
        dataSource={orderBy(tickets, 'createdAt', 'desc')}
        renderItem={(ticket: any) => (
          <ListItem style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', background: theme.colors.BACKGROUND, border: 'none', borderRadius: '5px', padding: '15px', gap: '10px' }}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', width: '75%'}}>
                <Text type='h5' style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{ticket?.description}</Text>
              </div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '5px', width: '25%'}}>
                <Text type='mini'>{dayjs(parseInt(ticket?.createdAt)).format('MMM DD')}</Text>
                  <Popover 
                    trigger='click'
                    content={<Text type='p'>Actions coming soon</Text>}
                    overlayInnerStyle={{background: theme.colors.BACKGROUND}}
                  >
                    <EditTicket />
                  </Popover>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px'}}>
                <Text type='mini'>{ticket?.createdByName}</Text>
              </div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                <Tag color='#A9FBC3' style={{color: theme.colors.FONT_INVERSE}}>{ticket?.category}</Tag>
                <Tag color='#A9FFF7' style={{color: theme.colors.FONT_INVERSE}}>{ticket?.server}</Tag>
                {/* <Popover 
                  trigger='click'
                  content={<CustomSegmented options={['New', 'Open', 'WIP', 'Closed', 'Resolved']} onChange={(e) => console.log(e)} />}
                  overlayInnerStyle={{background: theme.colors.BACKGROUND}}
                > */}
                  <Tag color='#FEAEAE' style={{color: theme.colors.FONT_INVERSE}}>{ticket?.status}</Tag>
                {/* </Popover> */}
                <CommentsTag onClick={() => setComments(ticket?.id)} active={ticket?.id === comments}>
                  <Comment />
                  <Text type='mini'>{ticketReplies?.filter(reply => reply?.ticketId === ticket?.id).length}</Text>
                </CommentsTag>
                <Priority priority={parseInt(ticket?.priority)} />
              </div>
            </div>
          </ListItem>   
        )}
      />
    </InfiniteScroll>
  )
}


const Elyzium = () => {
  const theme = useTheme()
  const [comments, setComments] = useState(null)
  const [filter, setFilter] = useState('All')

  const { data: elyziumTickets, isLoading: isLoadingElyziumTickets } = useElyziumTicketsQuery()
  const { data: elyziumTicketReplies, isLoading: _isLoadingElyziumTicketReplies } = useElyziumTicketRepliesQuery()

  const loading = isLoadingElyziumTickets
  if (loading) return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}><Spin size="large" /></div>

  const openTickets = elyziumTickets?.filter(ticket => ['NEW', 'OPEN'].includes(ticket?.status))
  // const wipTickets = elyziumTickets?.filter(ticket => ['WIP'].includes(ticket?.status))
  // const closedTickets = elyziumTickets?.filter(ticket => ['CLOSED', 'RESOLVED'].includes(ticket?.status))

  return (
    <UltraWrapper>

      <CustomTabs
        onChange={(tab) => setFilter(tab)}
        defaultActiveKey='All'
        items={['All', 'Open', 'Work in progress', 'Archived'].map((name) => {
          return {
            label: (
              <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                {name} tickets
                {name === 'Open' && <Badge style={{ backgroundColor: theme.colors.ERROR_300, color: theme.colors.FONT }}>{openTickets?.length}</Badge>}
              </div>
            ),
            key: name,
            children: (
              <TicketList tickets={elyziumTickets || []} setComments={setComments} comments={comments} filter={filter} ticketReplies={elyziumTicketReplies}/>
            ),
          }
        })}
      />
      <Comments visible={comments !== null} ticket={elyziumTickets?.find(ticket => ticket.id === comments)} setComments={setComments}/>
    </UltraWrapper>
  )
}

export default Elyzium
