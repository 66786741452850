import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ACCOUNT } from '../index'
import { useUser } from 'hooks/context/user-context'
import { useAccount } from 'hooks/context/account-context'

const getAccount = async () => {
  const { data } = await apiFetch(paths.accounts())
  return data
}

export const useAccountQuery = () => {
  const { session }: any = useUser()
  const { setAccount } = useAccount()

  return useQuery(
    [ACCOUNT],
    () => getAccount(),
    {
      staleTime: Infinity,
      enabled: !!session?.accessToken.jwtToken,
      refetchOnWindowFocus: false,
      onSuccess: async (account) => {
        // sets value to local storage and account context
        if (!account) return setAccount({})
        return setAccount(account)
      }
    }
  )
}
