export const ORGANIZATION = 'organization'

export const ACCOUNT = 'account'
export const ACCOUNT_INVITATIONS = [ACCOUNT, 'invitations']

export const ORGANIZATION_INVITATIONS = [ORGANIZATION, 'invitations', 'list']

export const USERS = 'users'
export const USER_ACTIVE = [USERS, 'active']
export const USERS_LIST = [USERS, 'list']

export const MEMBERSHIPS = 'memberships'

export * from './update-organization'
export * from './create-organization'
export * from './delete-organization'
export * from './get-organization'
export * from './get-organization-invitations'

export * from './accounts/get-invitations'
export * from './accounts/accept-invitation'
export * from './accounts/reject-invitation'

export * from './users/get-users'
export * from './users/get-user'
export * from './users/update-user'
export * from './users/delete-user'

export * from './invitations/send-invitation'

export * from './memberships/revoke-membership'
export * from './memberships/update-membership'

export * from './accounts/update-account'

export const organizationsPaths = {
  filter: (path, query) => `${path}?${query}`,


  accounts: () => `/accounts`,
  account: (accountId) => `${organizationsPaths.accounts()}/${accountId}`,
  accountInvitations: (accountId) => `/accounts/${accountId}/invitations`,

  organizations: () => `/organizations`,
  organization: (organizationId) => `${organizationsPaths.organizations()}/${organizationId}`,

  users: (organizationId) => `${organizationsPaths.organization(organizationId)}/users`,
  user: (organizationId, userId) => `${organizationsPaths.users(organizationId)}/${userId}`,
  userPermissions: (organizationId, userId) => `${organizationsPaths.user(organizationId, userId)}/permissions`,

  memberships: (organizationId) => `${organizationsPaths.organization(organizationId)}/accounts`,
  membership: (organizationId, accountId) => `${organizationsPaths.memberships(organizationId)}/${accountId}`,

  invitations: (organizationId) => `${organizationsPaths.organization(organizationId)}/invitations`,
  invitation: (organizationId, invitationId) => `${organizationsPaths.invitations(organizationId)}/${invitationId}`
}
