import styled, { withTheme } from 'styled-components'
import { isEmpty } from 'lodash'

import { useOrganization } from 'hooks/context/organization-context'
import Text from 'components/text'

const Container = withTheme(styled.div`
  display: flex;
  flex-direction: column;

  width: ${props => props.small ? '125px' : '150px'};
  height: ${props => props.small ? '125px' : '150px'};
  padding: 15px 10px;

  border-radius: 20px;

  background-color: ${props => props.theme.colors.BACKGROUND};

  &:hover {
    cursor: pointer;
  }
`)

const Tag = withTheme(styled.div`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 20px;
  background-color: ${props => props.negativeTag ? props.theme.colors.ERROR_100 : props.theme.colors.SUCCESS_200};
  color: ${props => props.negativeTag ? props.theme.colors.ERROR_500 : props.theme.colors.SUCCESS_500};

  border-radius: 5px;
`)

const Widget = ({ value = 0, description = 'Random test description', tag = 0, negativeTag = false, small, onClick }: any) => {
  const { organization: currentOrganization } = useOrganization()

  if (!isEmpty(currentOrganization)) {
    return (
      <Container small={small} onClick={onClick}>
        <Text type='label'>{description}</Text>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Text type='h1' style={{ marginRight: '20px' }}>{value}</Text>
          {(tag !== 0) && <Tag negativeTag={negativeTag}>{tag}</Tag>}
        </div>
      </Container>
    )
  }

  return null
}

export default Widget
