import styled from 'styled-components'

// Constants
import { device } from 'lib/constants/devices'
import Text from 'components/text'

export const Wrapper = styled.div`
  display: flex;
  max-height: 100vh;
  min-height: 100vh;

  padding: 0 3%;

  overscroll-behavior-y: none;
  background-size:cover;
`

export const LeftContainer = styled.div`
  flex: 2.5;
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    display: none;
  }
`

export const Media = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RightContainer = styled.div`
  flex: 5;
  display: flex;
`

export const HeadingText = styled(Text)`
  text-shadow: 0 0 30px #000000;
  position: absolute;
  display: block;
  top: 65%;
  left: 8%;
  z-index: 100;
`

export const FrameImage = styled.img`
  display: block;
  margin-left: 8%;
  max-width: 110%;
  filter: hue-rotate(346deg) saturate(1.5);
`

export const CrystalImage = styled.img`
  display: block;
  position: absolute;
  left: 65%;
  bottom: 25%;
  z-index: 100;
  max-width: 75%;
  max-height: 75%;
`
