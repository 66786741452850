import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { CONNECTOR, connectorsPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'
import { useConnector } from 'hooks/context/connector-context'

const putConnector = async (organization, connector, payload) => {
  const { data } = await apiFetch(paths.connector(organization, connector), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateConnectorQuery = () => {
  const queryClient = useQueryClient()
  const { organization }: any = useOrganization()
  const { connector, setConnector }: any = useConnector()

  return useMutation(
    (payload) => putConnector(organization?.id, connector?.id, payload),
    {
      onMutate: (payload: any) => {
        const previousConnector: any = queryClient.getQueryData([CONNECTOR, organization?.id])

        const newConnector = { ...previousConnector, ...payload }
        setConnector(newConnector)
        queryClient.setQueryData([CONNECTOR, organization?.id], newConnector)

        return { previousConnector }
      },
      onError: (err, newItem, context: { previousConnector: any }) => {
        if (err) queryClient.setQueryData([CONNECTOR, organization?.id], context.previousConnector)
      },
      // onSuccess: () => {
      //   refreshCurrentSession()
      // }
    }
  )
}
