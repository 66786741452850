import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { verificationPaths as paths, VERIFICATION } from './index'
import { useUser } from 'hooks/context/user-context'
import { useAccount } from 'hooks/context/account-context'

const postVerification = async (account, payload) => {
  const { data } = await apiFetch(paths.verifications(account), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

export const useCreateVerificationQuery = () => {
  const queryClient = useQueryClient()
  const { refreshCurrentSession } = useUser()
  const { account }: any = useAccount()

  return useMutation(
    (payload) => postVerification(account?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([VERIFICATION, account?.id])
        refreshCurrentSession()
      }
    }
  )
}
