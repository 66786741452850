import { isNil } from 'lodash'
import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { Form, Alert, message } from 'antd'

import DangerZone from 'components/DangerZone'
import CustomInput from 'components/Input'
import CustomButton from 'components/Button'
import Text from 'components/text'

import { useAccount } from 'hooks/context/account-context'
import { useDeleteUserQuery, useUpdateAccountQuery, useUpdateUserQuery } from 'hooks/api/organizations'

import { CategoryDivider, Asterix, UpdatePasswordButton, CategoryContainer, SpecialText } from './styles'

const ProfileTab = () => {
  const [newPasswordForm] = Form.useForm()

  const [error, setError] = useState('All fields are empty')
  const [displayName, setDisplayName] = useState()

  const { account } = useAccount()

  const { mutateAsync: updateAccount, isLoading: updateAccountLoading } = useUpdateAccountQuery()

  const onNewPasswordFormFinish = async (values) => {
    const { oldPassword, newPassword, confirmNewPassword } = values
    if (!oldPassword || !newPassword || !confirmNewPassword) return
    
    try {
      await Auth.currentSession()

      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      setError(null)
      message.success('Password updated', 5)

      newPasswordForm.resetFields()
    } catch (e) {
      if (e.message === 'Incorrect username or password.') setError('Incorrect current password')
      else setError(e.message)
      message.error('Could not update password', 5)
    }
  }

  return (<div>
    <Text type='h3'>Profile</Text>
    <Text type='label'>Update your personal details here</Text>

    <CategoryDivider />

    <CategoryContainer>
      <SpecialText type='label'>Username</SpecialText>
      <CustomInput disabled={updateAccountLoading} style={{width: '300px', height: '50px'}} placeholder={account?.name || 'Your display name'} onChange={(e) => setDisplayName(e.target.value)} onPressEnter={async () => {
        if (isNil(displayName)) return
        const account = updateAccount({ name: displayName })

        if (account) message.success('Successfully changed your display name')
        else message.error('Failed to update your display name')
      }} />
    </CategoryContainer>

    <CategoryDivider />

    {(error && error !== 'All fields are empty') && <Alert message={error} type='error' showIcon closable />}

    <Form name='newPasswordForm' form={newPasswordForm} onFinish={onNewPasswordFormFinish}>
      <CategoryContainer>
        <SpecialText type='label'>Current Password <Asterix>*</Asterix></SpecialText>
        <Form.Item name='oldPassword' rules={[{ required: true, message: 'Please input your password!' }]}>
          <CustomInput style={{width: '300px', height: '50px'}} placeholder='Your current password' password />
        </Form.Item>
      </CategoryContainer>
      
      <CategoryContainer>
        <SpecialText type='label'>New Password <Asterix>*</Asterix></SpecialText>
        <Form.Item name='newPassword' rules={[{ required: true, message: 'Please input your new password!' }]} hasFeedback>
          <CustomInput style={{width: '300px', height: '50px'}} placeholder='Your new password' password />
        </Form.Item>
      </CategoryContainer>

      <CategoryContainer>
        <SpecialText type='label'>Confirm New Password <Asterix>*</Asterix></SpecialText>
        <Form.Item name='confirmNewPassword' rules={[{ required: true, message: 'Please confirm your new password!' }, ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === value) {
              setError(null)
              return Promise.resolve();
            }
            setError('The two passwords that you entered do not match!')
            return Promise.reject();
          },
        })]} dependencies={['newPassword']} hasFeedback>
          <CustomInput style={{width: '300px', height: '50px'}} placeholder='Confirm your new password' password />
        </Form.Item>
      </CategoryContainer>

      <CategoryContainer>
        <SpecialText></SpecialText>
        <Form.Item>
          <CustomButton title='Update password' htmlType='submit' type='primary' disabled={error} />
        </Form.Item>
      </CategoryContainer>
    </Form>

    <CategoryDivider />

    {/* <DangerZone width='70%' onClick={async () => {
      const user = await deleteUser()
      if (!user) return message.error('Could not delete your account')
      message.success('Successfully deleted your account')
    }} /> */}
  </div>)
}

export default ProfileTab
