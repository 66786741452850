import React, { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Progress, Input, Button } from 'antd'
import { COLORS } from '../../../../lib/constants/colors'
import Text from 'components/text'
import CustomInput from 'components/Input'

const darkTheme = true

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100%;
  min-width: 100%;

  margin: 10px 0;
  padding: 60px 20px;
`

const FormInput = styled(Input)`
  height: 10%;
  font-size: 3rem;
  
  border: 1px solid ${darkTheme ? '#22272e' : '#EBEBEB'};
  border-radius: 5px;
  background-color: ${darkTheme ? '#22272e' : '#fbfbfb'};
  text-align: center;
  color: ${darkTheme ? COLORS.LIGHT_PRIMARY_200 : 'black'};
  
  &:hover {
    border: 1px solid ${darkTheme ? '#22272e' : '#f7f7f7'};
    box-shadow: 0px 3px 10px 0px ${darkTheme ? '#18191A' : '#d6d6d6'};
  }
  
  &:focus {
    border: 1px solid ${darkTheme ? '#22272e' : '#f7f7f7'};
    box-shadow: 0px 5px 20px 0px ${darkTheme ? '#000000' : '#d6d6d6'};
    background-color: ${darkTheme ? '#22272e' : 'white'};
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`


const Step = ({ children, setOrganizationName, organizationName }) => {

  return <Wrapper>
      <Text type='h1' style={{marginBottom: 'auto', textAlign: 'center'}}>Creating an Organization</Text>
      <Text type='h4'>Please enter your organization's name</Text>
      <Text type='p' style={{marginBottom: '10px'}}>This is usually your server's name</Text>
      <CustomInput placeholder="Organization's name" onChange={e => setOrganizationName(e.target.value)} value={organizationName} style={{ marginBottom: 'auto', height: '10%', fontSize: '3rem', textAlign: 'center' }} />
    </Wrapper>
}

export default Step
