import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, MEMBERSHIPS, useOrganizationQuery } from '../index'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'

const deleteMembership = async (organizationId, accountId) => {
  const { data } = await apiFetch(paths.membership(organizationId, accountId), { method: 'DELETE' })
  return data
}

export const useRevokeMembershipQuery = () => {
  const queryClient = useQueryClient()
  const { refetch } = useOrganizationQuery()

  const { refreshCurrentSession }: any = useUser()
  const { organization }: any = useOrganization()

  return useMutation(
    (accountId) => deleteMembership(organization?.id, accountId),
    {
      onSuccess: () => {
        refreshCurrentSession(refetch)
        queryClient.invalidateQueries([MEMBERSHIPS, organization?.id])
      }
    }
  )
}
