import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Auth } from 'aws-amplify'

export const UserContext = React.createContext({
  user: null,
  session: null,
  authMessage: null,
  authenticated: false,
  initialUserLoading: true,
  logout: () => { },
  getCurrentSession: () => { },
  refreshCurrentSession: (action) => { },
  setAuthMessage: (message) => { },
  setAuthenticated: (authenticated) => { },
  setUser: (userInfo) => { },
  setSession: (session) => { }
})

export const UserProvider = (props) => {
  const [user, setUser] = useState(null)
  const [initialUserLoading, setInitialUserLoading] = useState(true)
  const [session, setSession] = useState(null)
  const [authenticated, setAuthenticated] = useState(false)
  const [authMessage, setAuthMessage] = useState(false)

  const sessionExpired = (Date.now() - session?.accessToken?.payload?.exp * 1000) >= 0

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        // häkkerman to get email from query before react router is initiated and then not log i
        // const emailInQuery = queryString.parse(window.location.href.split('?')[1])?.email
        // if (emailInQuery && emailInQuery !== user.attributes.email) return
        setAuthenticated(true)
        setUser(user)
        setSession(user?.signInUserSession)
        setInitialUserLoading(false)
      })
      .catch(() => {
        setInitialUserLoading(false)
        setUser(null)
      })
  }, [])

  //  maybe this is not necessary at all
  useEffect(() => {
    if (!sessionExpired) return
    getCurrentSession()
  }, [sessionExpired])

  const logout = () => {
    Auth.signOut()
      .then((data) => {
        setUser(null)
        setSession(null)
        setAuthenticated(false)
        return data
      })
  }

  const getCurrentSession = () => {
    Auth.currentSession()
      .then(data => {
        setSession(data)
        setAuthenticated(true)
        return data
      })
      .catch(err => { console.log('curr session error', err) })
  }

  const refreshCurrentSession = async (action) => {
    const currentUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()
    currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) setAuthMessage({ message: err })
      else {
        setAuthenticated(true)
        setSession(session)
        setUser(currentUser)
        action && action()
      }
    })
  }

  const values = useMemo(() => ({
    user,
    authenticated,
    session,
    authMessage,
    initialUserLoading,
    setUser,
    setAuthenticated,
    setSession,
    setAuthMessage,
    logout,
    getCurrentSession,
    refreshCurrentSession
  }), [user, session, authenticated, authMessage, initialUserLoading])

  return (
    <UserContext.Provider value={values}>
      {props.children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('`useUser` must be within a `UserProvider`')
  }
  return context
}
