import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { connectorsPaths as paths, CONNECTOR } from './index'
import { useOrganization } from 'hooks/context/organization-context'
import { useConnector } from 'hooks/context/connector-context'

const getConnectors = async (organization) => {
  const { data } = await apiFetch(paths.connectors(organization))
  return data
}

export const useConnectorsQuery = () => {
  const { organization }: any = useOrganization()
  const { setConnector }: any = useConnector()

  return useQuery(
    [CONNECTOR, organization?.id],
    () => getConnectors(organization?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: async (connector) => {
        // sets value to local storage and organization context
        if (!connector) return setConnector(undefined)
        return setConnector(connector)
      }
    }
    
  )
}
