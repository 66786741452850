import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Card, Row, Col } from 'antd'

import Text from 'components/text'

import SuspensionButton from './SuspensionButton'

const DangerZoneCard = styled(Card)`
  border: none;
  background: transparent;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
  margin: 30px 10px;
  width: ${props => props.width};
`

const SuspensionText = () => {
  const title = `Delete your account`
  const text = 'Once you delete you do this, we will delete your account entirely. If you are the owner of an organization, it will be deleted alongside your account. All of your data will be deleted permanently & cannot be reclaimed.'

  return (
    <Row>
      <Col>
        <Text type='h4'>{title}</Text>
      </Col>
      <Col>
        <Text type='label'>{text}</Text>
      </Col>
    </Row>
  )
}

const OrgSuspensionText = () => {
  const title = 'Delete your organization'
  const text = 'Once you delete you do this, we will delete your organization entirely. All connection data will be deleted permanently & cannot be reclaimed. The organization users will also lose access to the organization.'

  return (
    <Row>
      <Col>
      <Text type='h4'>{title}</Text>
      </Col>
      <Col>
        <Text type='label'>{text}</Text>
      </Col>
    </Row>
  )
}

export const OrgDangerZone = ({ onClick, width = '100%' }) => {
  const theme = useTheme()

  return (
    <DangerZoneCard
      width={width}
      title='Danger Zone'
      loading={false}
      headStyle={{ backgroundColor: theme.colors.ERROR_100, border: 'none', color: theme.colors.FONT }}
    >
      <Row>
        <Col span={14}>
          <OrgSuspensionText />
        </Col>
        <Col offset={2} span={8} style={{ display: 'flex', alignItems: 'center' }}>
          <SuspensionButton organization={true} onClick={onClick} />
        </Col>
      </Row>
    </DangerZoneCard>
  )
}

const DangerZone = ({ onClick, width = '100%' }) => {
  const theme = useTheme()

  return (
    <DangerZoneCard
      title='Danger Zone'
      width={width}
      loading={false}
      headStyle={{ backgroundColor: theme.colors.ERROR_100, border: 'none', color: theme.colors.FONT }}
    >
      <Row>
        <Col span={14}>
          <SuspensionText />
        </Col>
        <Col offset={2} span={8} style={{ display: 'flex', alignItems: 'center' }}>
          <SuspensionButton onClick={onClick} />
        </Col>
      </Row>
    </DangerZoneCard>
  )
}

export default DangerZone
