export const COLORS = {
  LIGHT_PRIMARY_100: '#f4f7fc',
  LIGHT_PRIMARY_200: '#eff3fb',
  LIGHT_PRIMARY_300: '#dee7f7',
  LIGHT_PRIMARY_400: '#cedbf3',
  LIGHT_PRIMARY_500: '#bdcfef',

  LIGHT_SECONDARY_100: '#8a9be8',
  LIGHT_SECONDARY_200: '#43449B',

  DARK_PRIMARY_100: '#525252',
  DARK_PRIMARY_200: '#474747',
  DARK_PRIMARY_300: '#3D3D3D',
  DARK_PRIMARY_400: '#333333',
  DARK_PRIMARY_500: '#292929',

  DARK_SECONDARY_100: '#333867',
  DARK_SECONDARY_200: '#2F3460',
  DARK_SECONDARY_300: '#282D52',
  DARK_SECONDARY_400: '#222644',
  DARK_SECONDARY_500: '#1B1E36',

  SECONDARY_100: '#fdb09b',
  SECONDARY_200: '#fda187',
  SECONDARY_300: '#FC7A57',

  MINDARO_100: '#DDFC74',
  MINDARO_200: '#D7FC5F',
  MINDARO_300: '#D2FB4B',

  SUCCESS: '#e5f9ee',
  SUCCESS_100: '#9ee493',
  SUCCESS_200: '#89de7c',
  SUCCESS_300: '#6cd65c',

  ERROR_100: '#FF675C',
  ERROR_200: '#FF4133',
  ERROR_300: '#FF1B0A',

  WARNING_100: '#F8FA90',
  WARNING_200: '#F6F977',
  WARNING_300: '#F4F750',

  INFO_100: '#9CDEE7',
  INFO_200: '#6BCEDB',
  INFO_300: '#3ABECF',
}