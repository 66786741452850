import { Auth } from 'aws-amplify'
import { useQuery } from 'react-query'

import { USER_ACTIVE } from '../index'
import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'

const getUser = async () => {
  const userInfo = await Auth.currentUserInfo()
  const { idToken: { payload } }: any = await Auth.currentSession()
  return {
    ...userInfo,
    id: payload && payload['cognito:username']
  }
}

export const useUserQuery = () => {
  const { organization }: any = useOrganization()
  const { user }: any = useUser()

  return useQuery(
    [...USER_ACTIVE, user?.attributes?.sub],
    () => getUser(),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!user?.attributes?.sub,
      refetchOnWindowFocus: false
    }
  )
} 
