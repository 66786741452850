import React from 'react'

// import 'antd/dist/antd.css'
import { Input, Popover, Table } from 'antd'

const columns = [
  {
    title: '',
    dataIndex: 'name',
  },
  {
    title: 'View Only',
    dataIndex: 'viewer',
  },
  {
    title: 'User',
    dataIndex: 'user',
  },
  {
    title: 'Admin',
    dataIndex: 'admin',
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
  },
]

const data = [
  {
    key: 'update.org.info',
    name: 'Update Organization Info',
    viewer: '',
    user: '',
    admin: '',
    owner: 'x'
  }, {
    key: 'delete.org',
    name: 'Delete Organization',
    viewer: '',
    user: '',
    admin: '',
    owner: 'x'
  }, {
    key: 'invite.users',
    name: 'Invite new user',
    viewer: '',
    user: '',
    admin: '',
    owner: 'x'
  }, {
    key: 'remove.users',
    name: 'Remove user from organization',
    viewer: '',
    user: '',
    admin: '',
    owner: 'x'
  }, {
    key: 'edit.user.permissions',
    name: 'Edit user permissions',
    viewer: '',
    user: '',
    admin: '',
    owner: 'x'
  }, {
    key: 'org.create.servers',
    name: 'Add new servers',
    viewer: '',
    user: '',
    admin: '',
    owner: 'x'
  }, {
    key: 'org.delete.servers',
    name: 'Delete servers',
    viewer: '',
    user: '',
    admin: '',
    owner: 'x'
  }, {
    key: 'org.view.servers',
    name: 'View server cards',
    viewer: 'x',
    user: 'x',
    admin: 'x',
    owner: 'x'
  }, {
    key: 'org.edit.servers',
    name: 'Edit server cards\' information',
    viewer: '',
    user: '',
    admin: 'x',
    owner: 'x'
  }, {
    key: 'ely.view.data',
    name: 'View Elyzium data',
    viewer: 'x',
    user: 'x',
    admin: 'x',
    owner: 'x'
  }, {
    key: 'ely.manage.data',
    name: 'Manage Elyzium data',
    viewer: '',
    user: '',
    admin: 'x',
    owner: 'x'
  }, {
    key: 'ely.manage.tickets',
    name: 'Manage Elyzium tickets',
    viewer: '',
    user: 'x',
    admin: 'x',
    owner: 'x'
  }, {
    key: 'ely.view.tickets',
    name: 'View Elyzium tickets',
    viewer: 'x',
    user: 'x',
    admin: 'x',
    owner: 'x'
  }
];

const PermissionsPopover = ({ children, placement = 'top' }) => {
  const content = (<div>
    <Table columns={columns} dataSource={data} size="middle" pagination={false}/>
  </div>)

  return <Popover placement={placement} content={content} title='Permissions'>
    {children}
  </Popover>
  
}

export default PermissionsPopover