import styled from 'styled-components'

import { Input } from 'antd'

const InputField = styled(Input)`
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 12px;
  border: none;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;

  &:focus {
    border: 1px solid #f7f7f7;
    box-shadow: 0px 2px 5px 0px #d6d6d6;
    background-color: white;
  }
`

const PasswordInputField = styled(Input.Password)`
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
  &:hover {
    box-shadow: 0px 1px 5px 0px #d6d6d6;
  }
`

const CustomInput = ({ value, onChange, prefix, placeholder, type, disabled, style, password = false, onPressEnter }: any) => {
  if (password) return <PasswordInputField value={value} prefix={prefix} placeholder={placeholder} type={type} onChange={onChange} disabled={disabled} style={style}  />

  return <InputField value={value} prefix={prefix} placeholder={placeholder} type={type} onChange={onChange} disabled={disabled} style={style} onPressEnter={onPressEnter} />
}

export default CustomInput