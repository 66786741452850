export const CONNECTOR = 'CONNECTOR'
export const CUSTOM_QUERY = 'CUSTOM_QUERY'

export * from './get-connectors'
export * from './update-connector'
export * from './create-connector'

export * from './query/custom-query'

export const connectorsPaths = {
  filter: (path, query) => `${path}?${query}`,

  organization: (identity) => `/organizations/${identity}`,
  base: (organization) => `/organizations/${organization}/connectors`,

  connectors: (organization) => `${connectorsPaths.base(organization)}/connectors`,
  connector: (organization, identity) => `${connectorsPaths.connectors(organization)}/${identity}`,

  query: (organization, connector) => `${connectorsPaths.connector(organization, connector)}/custom-query`,
}
