export const MSG_INVITATION_NOT_FOUND = {
  title: 'Invitation Not Found',
  message: 'Unfortunately, we did not find an invitation with this email and token, please log in or contact your administrator!'
}

export const MSG_INVITATION_EXPIRED = {
  title: 'Invitation Expired',
  message: 'This invitation has expired, please contact your administrator for a new invitation!'
}

export const MSG_INVITATION_ERROR = {
  title: 'Error',
  message: 'There has been an error with retrieving your invitation, please contact your administrator!'
}

export const MSG_CONFIRMATION_CODE = {
  title: 'Confirmation Code',
  message: 'We have sent you a confirmation code, please check your email and submit the code to verify your identity!'
}

export const MSG_USER_ALREADY_EXISTS = {
  title: 'User Already Exists',
  message: 'Please log in with your credentials to accept the invite!'
}

export const MSG_PASSWORD_CHANGE_REQUIRED = {
  title: 'Password Change Required',
  message: 'Please choose and confirm your new password!'
}