import React from 'react'

// Specialized components
import { Wrapper, VerticalNavigationBarContainer, ContentWrapper } from './styles'

// General components
import VerticalNavigationBar from 'components/VerticalNavBar'
import { useUser } from 'hooks/context/user-context'

const Layout = ({ children }) => {
  const { user } = useUser()

  return (
    <Wrapper>
      <VerticalNavigationBarContainer>
        <VerticalNavigationBar name={user?.attributes?.['custom:custom:displayName']} />
      </VerticalNavigationBarContainer>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default Layout
