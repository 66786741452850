import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, notification } from 'antd'

// General components
import ForgotPassword from './components/ForgotPassword'
import ForgotPasswordConfirm from './components/ForgotPasswordConfirm'

// Specialized components
import { Wrapper } from './styles'

import { useForgotPassword, useForgotPasswordSubmit, useLogin } from 'hooks/auth'
import { useUser } from 'hooks/context/user-context'

const ForgotPasswordPage = () => {
  const history = useHistory()
  const { mutate: signIn } = useLogin()
  const { mutate: forgotPassword, isSuccess: forgotPasswordEmailSent, isLoading: forgotPasswordLoading } = useForgotPassword()
  const { mutate: forgotPasswordSubmit, isSuccess: forgotPasswordSubmitSuccess, isLoading: forgotPasswordSubmitLoading } = useForgotPasswordSubmit()
  const { authMessage: authError } = useUser()

  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const onForgotPassword = async () => {
    forgotPassword(email)
  }

  const onForgotPasswordSubmit = async () => {
    forgotPasswordSubmit({ email, code, password })
  }

  useEffect(() => {
    if (forgotPasswordSubmitSuccess) {
      notification.success({
        message: 'Successfully changed the password',
        placement: 'topRight',
        duration: 5
      })

      signIn({ email, password })
      history.push('/')
    }
  }, [forgotPasswordSubmitSuccess])

  return forgotPasswordEmailSent
  ? <Wrapper>{authError.message && <Alert
    message="Error"
    description={authError.message}
    type="error"
    showIcon
  />}<ForgotPasswordConfirm setCode={setCode} setPassword={setPassword} setConfirmPassword={setConfirmPassword} onSubmit={onForgotPasswordSubmit} loading={forgotPasswordSubmitLoading} email={email} password={password} confirmPassword={confirmPassword} code={code} /></Wrapper>
    : <Wrapper>{authError.message && <Alert
    message="Something went wrong"
    description={authError.message}
    type="error"
    showIcon
  />}<ForgotPassword email={email} setEmail={setEmail} onSubmit={onForgotPassword} loading={forgotPasswordLoading} /></Wrapper>
}

export default ForgotPasswordPage
